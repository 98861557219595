import { EventDescription } from 'types/EventDescription'
import { Log } from 'types/Log'
import { format } from 'date-fns'

export const previousCheckOut = (
  log: Log,
  pRef: EventDescription['pRef']
) => `<p>This ${pRef} has previously checked out at ${format(
  new Date(log.timeOut),
  'HH:mm:ss'
)}.</p>
      <p>This will remove their previous score of ${log.totalScore}.</p>
      <b>Are you sure you want to do this?</b>`

export const previousCheckIn = (
  log: Log,
  pRef: EventDescription['pRef']
) => `<p>This ${pRef} has been checked in previously at ${format(
  new Date(log.timeIn),
  'HH:mm:ss'
)}.</p>
      <p>This action will change the check in time to now.</p>
      <b>Are you sure you want to do this?</b>`

export const noCheckIn = (pRef: EventDescription['pRef']) => `<p>
      This patrol has not been registered as having checked in, did you enter the correct ${pRef} number? <p>If not then click cancel.</p>
      </p>
      <br>
      <br>
      <p>If this is the correct ${pRef} number then click ok.
      </p>
      <p>In future check in a ${pRef} when they arrive at the base to avoid this message.</p>`
