import { FieldAttributes, useField } from 'formik'

import { InputErrorMessage } from 'components/InputErrorMessage/InputErrorMessage'
import React from 'react'
import { Select } from 'react-onsenui'

export type FormikSelectProps = FieldAttributes<any>

export const FormikSelect = ({
  label,
  options,
  ...props
}: FormikSelectProps) => {
  const [field, meta] = useField(props)
  const hasError = Boolean(meta.touched && meta.error)

  return (
    <>
      <Select {...field} {...props}>
        {(options as { text: string; value: string }[]).map(
          ({ text, value }) => (
            <option key={value} value={value}>
              {text}
            </option>
          )
        )}
      </Select>
      <InputErrorMessage hasError={hasError} error={meta.error} />
    </>
  )
}
