import { Button, ListHeader, Page } from 'react-onsenui'
import { Log, LogAction } from 'types/Log'
import { LoggingDialog, LoggingDialogType } from './LoggingDialog/LoggingDialog'
import React, { useState } from 'react'

import { ActionButtons } from './ActionButtons/ActionButtons'
import { IconDataBox } from 'components/IconDataBox/IconDataBox'
import { currentEventSelector } from 'ReduxState/reducers/currentEvent'
import styles from './LoggingActions.module.css'
import { useAppSelector } from 'ReduxState/hooks'
import { useFind } from 'use-pouchdb'

export type LoggingActionsProps = {}

export const LoggingActions = ({}: LoggingActionsProps) => {
  const { eventDescription, activeBase, base } =
    useAppSelector(currentEventSelector)
  const [dialog, setDialog] = useState<LoggingDialogType>()
  const { pRef = 'Team' } = eventDescription || {}
  const { isTransport, baseMaxScore } = base || {}
  const isCheckpoint = !baseMaxScore && !isTransport

  let baseTerm = 'Base'
  if (isTransport) {
    baseTerm = 'Transport'
  }

  const { docs: atBase } = useFind<Log>({
    index: { fields: ['base', 'action'] },
    selector: {
      base: activeBase,
      action: LogAction.CHECK_IN,
    },
  })
  const { docs: completedBase } = useFind<Log>({
    index: { fields: ['base', 'action'] },
    selector: {
      base: activeBase,
      action: LogAction.CHECK_OUT,
    },
  })

  return (
    <Page>
      <div className={styles.pageContainer}>
        <div>
          <ListHeader style={{ backgroundColor: '#eee' }}>
            {baseTerm} Dashboard
          </ListHeader>
          <div className={styles.dashboardContainer}>
            {!isCheckpoint && (
              <IconDataBox
                icon="fa-users"
                title={`${pRef}s ${isTransport ? 'on Transport' : 'at Base'}`}
              >
                {atBase.length}
              </IconDataBox>
            )}
            <IconDataBox
              icon={isTransport ? 'fa-bus' : 'fa-check'}
              title={`${pRef}s ${
                isTransport ? 'used Transport' : `completed ${baseTerm}`
              }`}
            >
              {completedBase.length}
            </IconDataBox>
          </div>
        </div>
        <div>
          <ListHeader style={{ backgroundColor: '#eee' }}>
            {baseTerm} Actions
          </ListHeader>
          <div className={styles.actionContainer}>
            <ActionButtons
              pRef={pRef}
              baseNo={activeBase}
              baseTerm={baseTerm}
              setDialog={setDialog}
              isCheckpoint={isCheckpoint}
            />
          </div>
        </div>
      </div>

      <LoggingDialog
        dialogType={dialog}
        onClose={() => {
          setDialog(undefined)
        }}
      />
    </Page>
  )
}
