import {
  BackButton,
  BottomToolbar,
  Fab,
  Icon,
  Navigator,
  Page,
  Toolbar,
} from 'react-onsenui'
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import {
  currentEventSelector,
  setLastMsgId,
} from 'ReduxState/reducers/currentEvent'
import { useAllDocs, usePouch } from 'use-pouchdb'

import { AppToolbar } from 'components/AppToolbar/AppToolbar'
import { DateBubble } from 'components/DateBubble/DateBubble'
import { Message } from 'components/Message/Message'
import { MessageLog } from 'types/MessageLog'
import { SendMessageToolbar } from './SendMessageToolbar/SendMessageToolbar'
import { isSameDay } from 'date-fns'
import ons from 'onsenui'
import { useAppSelector } from 'ReduxState/hooks'
import { useDispatch } from 'react-redux'
import { useIntersectionObserver } from 'usehooks-ts'

let lastMsg: MessageLog | undefined

export type MessagingPageProps = { navigator: Navigator }

export const MessagingPage = ({ navigator }: MessagingPageProps) => {
  const db = usePouch()
  const dispatch = useDispatch()
  const [hasScrolled, setHasScrolled] = useState(false)
  const [seenMessages, setSeenMessages] = useState(0)

  const { activeBase } = useAppSelector(currentEventSelector)

  const scrollRef = useRef<HTMLDivElement | null>(null)

  const { rows = [] } = useAllDocs<MessageLog>({
    include_docs: true,
    endkey: 'message',
    startkey: 'message\ufff0',
    descending: true,
  })

  const { isIntersecting, ref } = useIntersectionObserver({
    threshold: 0.5,
  })

  const [messages, setMessages] = useState(rows)

  const removeMsg = useCallback(
    async (doc: PouchDB.Core.ExistingDocument<MessageLog>) => {
      try {
        await db.remove(doc)
      } catch (e) {
        ons.notification.alert('Failed to delete message')
      }
    },
    [db]
  )

  const scrollToNewMessages = useCallback(
    (behavior?: ScrollBehavior) => {
      scrollRef.current?.scrollIntoView({ behavior })
      setSeenMessages(rows.length)
    },
    [scrollRef, rows]
  )

  // new messages
  useEffect(() => {
    if (rows.length || messages.length) {
      setMessages([...rows].reverse())
      if (isIntersecting) {
        setHasScrolled(false)
      }
    }
  }, [rows, isIntersecting, scrollToNewMessages, messages.length])

  useEffect(() => {
    if (messages.length && isIntersecting) {
      dispatch(setLastMsgId(rows[0]?.id || 'message'))
    }
  }, [dispatch, isIntersecting, messages.length, rows])

  // initial scroll to bottom
  useEffect(() => {
    if (!hasScrolled && messages.length && scrollRef.current) {
      scrollToNewMessages()
      setHasScrolled(true)
    }
  }, [hasScrolled, messages.length, scrollRef, scrollToNewMessages])

  return (
    <Page
      id="messagesPage"
      renderToolbar={() => (
        <AppToolbar
          navigator={navigator}
          hideSearch
          hideMessaging
          title="Messages"
          backButton
        />
      )}
      renderFixed={() => (
        <SendMessageToolbar
          showScrollButton={hasScrolled && !isIntersecting}
          onScrollClick={() => {
            scrollToNewMessages('smooth')
          }}
          notSeenMessages={rows.length - seenMessages}
        />
      )}
    >
      <div className="" id="messageWindow">
        {messages.map(({ doc }) => {
          if (!doc) {
            return null
          }
          const { from = -1, username = '', time = '', message, _id } = doc
          const msgContinued =
            lastMsg?.from === from && lastMsg?.username === username

          // check if the dates are the same
          let sameDay = false
          if (lastMsg?.time && time) {
            const lastDate = new Date(lastMsg.time)
            const msgDate = new Date(time)
            if (isSameDay(lastDate, msgDate)) {
              sameDay = true
            }
          }

          // update the reference message
          lastMsg = doc

          return (
            <Fragment key={_id}>
              {!sameDay && <DateBubble date={new Date(time)} />}
              <Message
                from={from ? from : 'HQ'}
                username={username}
                time={time}
                msgOut={activeBase === from}
                msgContinued={msgContinued}
                onRemove={() => removeMsg(doc)}
              >
                {message}
              </Message>
            </Fragment>
          )
        })}
      </div>
      <div ref={scrollRef} id="newMessages" />
      <div ref={ref} />
    </Page>
  )
}
