import React, { useEffect, useRef } from 'react'
import {
  forcePageChange,
  layoutSelector,
  toggleSidebar,
} from 'ReduxState/reducers/layout'
import { useAppDispatch, useAppSelector } from 'ReduxState/hooks'

import { EventSelectionPage } from './EventSelectionPage/EventSelectionPage'
import { Home } from './Home/Home'
import { Navigator } from 'react-onsenui'
import { Routes } from 'constants/routes'
import { SyncRoutes } from './SyncRoutes'

export type AppRoutesProps = {
  route: { title: Routes; hasBackButton?: boolean }
  navigator: Navigator
}
let update = false

export const AppRoutes = ({ route, navigator }: AppRoutesProps) => {
  const dispatch = useAppDispatch()
  const { forcePage } = useAppSelector(layoutSelector)

  useEffect(() => {
    const forcingPage = async () => {
      if (forcePage && !update) {
        update = true
        dispatch(forcePageChange())
        const { method } = forcePage
        if (method === 'pop') {
          await navigator.popPage()
        } else {
          const { page } = forcePage

          await navigator.pushPage({ title: page })
        }
        if (method === 'push' && forcePage.page === Routes.HOME) {
          dispatch({ type: 'logout' })
          dispatch(toggleSidebar(false))
        } else {
          dispatch(toggleSidebar(false))
        }
      }
      if (!forcePage && update) {
        update = false
      }
    }
    forcingPage()
  }, [forcePage, dispatch, navigator])

  switch (route.title) {
    case Routes.MESSAGING_PAGE:
    case Routes.ADMIN:
    case Routes.EVENT_SIGN_IN_PAGE:
    case Routes.BASE_PAGE:
      return <SyncRoutes route={route} navigator={navigator} key="syncRoutes" />
    case Routes.EVENT_SELECTION_PAGE:
      return (
        <EventSelectionPage
          navigator={navigator}
          key={Routes.EVENT_SELECTION_PAGE}
        />
      )
    case Routes.HOME:
    default:
      return <Home navigator={navigator} key={Routes.HOME} />
  }
}
