import {
  currentEventSelector,
  setLastSync,
} from 'ReduxState/reducers/currentEvent'
import { useAppDispatch, useAppSelector } from 'ReduxState/hooks'

import { EventDescription } from 'types/EventDescription'
import { Log } from 'types/Log'
import { MessageLog } from 'types/MessageLog'
import PouchDB from 'pouchdb-browser'
import ons from 'onsenui'
import { useEffect } from 'react'
import { userSelector } from 'ReduxState/reducers/user'

export const usePouchDBSync = (baseNo?: number | undefined) => {
  const { currentDb } = useAppSelector(currentEventSelector)
  const { baseRemoteUrl } = useAppSelector(userSelector)
  const dispatch = useAppDispatch()

  useEffect(() => {
    console.log('setting up sync', { currentDb })
    if (!currentDb) {
      return
    }
    const remoteUrl = `${baseRemoteUrl}/${currentDb}`
    let sync: PouchDB.Replication.Sync<{}> | undefined
    const db = new PouchDB(currentDb)

    db.replicate.from(remoteUrl).on('complete', () => {
      dispatch(setLastSync())
      // sync after full replication download
      sync = PouchDB.sync<EventDescription | Log | MessageLog>(
        currentDb,
        remoteUrl,
        {
          live: true,
          retry: true,
        }
      )
        .on('change', (info) => {
          dispatch(setLastSync())
          // const { direction, change } = info
          // // handle change
          // if (direction === 'pull') {
          //   // const { doc_write_failures, docs_read, docs_written, ok, docs } =
          //   //   change
          //   console.log('change occured in remote updating basedb')
          //   console.log(change)

          //   // docs.forEach(({ _id, _rev, _deleted, ...doc }) => {})
          //   // dbUpdateFromFindOrChange(change, false)
          // } else {
          //   console.log('updating remotedb')
          // }
        })
        .on('paused', function (err) {
          // replication paused (e.g. replication up to date, user went offline)
          // show offline indicator
        })
        .on('active', function () {
          // replicate resumed (e.g. new changes replicating, user went back
          // online)
          // todo: remove offline indicator
          dispatch(setLastSync())
        })
        .on('denied', function (err) {
          // a document failed to replicate (e.g. due to permissions)
          console.log('denied', err)
        })
        .on('complete', function (info) {
          // handle complete
        })
        .on('error', function (err) {
          // handle error
          ons.notification.alert(
            'There was an issue connecting to the services, try refreshing the page'
          )
        })
    })

    return () => {
      console.log('sync cancelled')
      // whenever you want to cancel
      sync?.cancel()
    }
  }, [baseRemoteUrl, currentDb, dispatch])
}
