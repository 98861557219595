import {
  AnyAction,
  PayloadAction,
  combineReducers,
  configureStore,
} from '@reduxjs/toolkit'

import { adminFunctionsSlice } from './reducers/adminFunctions'
import { currentEventSlice } from './reducers/currentEvent'
import { layoutSlice } from './reducers/layout'
import { userSlice } from './reducers/user'

export const reducers = combineReducers({
  [layoutSlice.name]: layoutSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [currentEventSlice.name]: currentEventSlice.reducer,
  [adminFunctionsSlice.name]: adminFunctionsSlice.reducer,
})

export const rootReducer = (state: any, action: PayloadAction<any>) => {
  if (action.type === 'logout') {
    return reducers(undefined, action)
  }
  return reducers(state, action)
}
export const store = configureStore({
  reducer: rootReducer,
  // devTools: true,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
