import { Navigator, Page, Tab, Tabbar } from 'react-onsenui'

import { AllLogsPage } from './AllLogsPage/AllLogsPage'
import { AppToolbar } from 'components/AppToolbar/AppToolbar'
import { LastSeenPage } from './LastSeenPage/LastSeenPage'
import { Leaderboard } from './LeaderboardPage/Leaderboard'
import { Log } from 'types/Log'
import { currentEventSelector } from 'ReduxState/reducers/currentEvent'
import { useAppSelector } from 'ReduxState/hooks'
import { useFind } from 'use-pouchdb'

export type AdminProps = { navigator: Navigator }

export const Admin = ({ navigator }: AdminProps) => {
  const { teamSearch } = useAppSelector(currentEventSelector)
  const { docs } = useFind<Log>({
    // Ensure that this index exist, create it if not. And use it.
    index: {
      fields: ['timeOut', 'patrol'],
      // 'ddoc' and 'name' are not required. PouchDB will check all existing indexes
      // if they match the requirements. And only create a one if none match.
    },
    selector: {
      timeOut: {
        $gt: 0,
      },
      patrol: typeof teamSearch === 'string' ? teamSearch : undefined,
    },
    sort: [{ timeOut: 'desc' }],
  })

  return (
    <Page
      renderToolbar={() => <AppToolbar title="Admin" navigator={navigator} />}
    >
      <Tabbar
        position="auto"
        index={0}
        renderTabs={() => [
          {
            content: <AllLogsPage docs={docs} />,
            tab: <Tab label="All Logs" icon="md-view-headline" />,
          },
          {
            content: <LastSeenPage docs={docs} />,
            tab: <Tab label="Last Seen" icon="fa-binoculars" />,
          },
          {
            content: <Leaderboard />,
            tab: <Tab label="Leaderboard" icon="fa-trophy" />,
          },
        ]}
      />
    </Page>
  )
}
