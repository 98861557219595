import React, { PropsWithChildren } from 'react'

import { CardMapper } from '../CardMapper/CardMapper'
import { EventDescription } from 'types/EventDescription'
import { Navigator } from 'react-onsenui'

export type EventGroupProps = PropsWithChildren<{
  events: EventDescription[]
  navigator: Navigator
}>

export const EventGroup = ({
  events,
  children,
  navigator,
}: EventGroupProps) => {
  if (!events.length) {
    return null
  }
  return (
    <div>
      <div className="card shadow--2dp eventTitle">{children}</div>
      <CardMapper events={events} navigator={navigator} />
    </div>
  )
}
