import React from 'react'
import styles from './InputErrorMessage.module.css'

export type InputErrorMessageProps = { hasError?: boolean; error?: string }

export const InputErrorMessage = ({
  hasError,
  error,
}: InputErrorMessageProps) => {
  if (!hasError) {
    return null
  }
  return <small className={styles.errorMsg}>{error}</small>
}
