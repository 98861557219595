import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { Routes } from 'constants/routes'
import { createSlice } from '@reduxjs/toolkit'

export type SidebarSliceState = {
  sidebarOpen: boolean
  loaderOn?: boolean
  forcePage?: { page: Routes; method: 'push' } | { method: 'pop' }
}

export const initialState: SidebarSliceState = {
  sidebarOpen: false,
}

export const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    toggleSidebar: (state, { payload }: PayloadAction<boolean | undefined>) => {
      state.sidebarOpen =
        typeof payload === 'boolean' ? payload : !state.sidebarOpen
    },
    toggleLoader: (state, { payload }: PayloadAction<boolean | undefined>) => {
      state.loaderOn = typeof payload === 'boolean' ? payload : !state.loaderOn
    },
    forcePageChange: (
      state,
      {
        payload,
      }: PayloadAction<
        { page: Routes; method: 'push' } | { method: 'pop' } | undefined
      >
    ) => {
      if (payload?.method) {
        state.forcePage = payload
      } else {
        delete state.forcePage
      }
    },
  },
})

export const { toggleSidebar, toggleLoader, forcePageChange } =
  layoutSlice.actions
export const layoutSelector = (state: RootState) => state[layoutSlice.name]
