import { Log, LogAction } from 'types/Log'

import { baseLogIdGenerator } from 'utils/logIdGenerators'
import { clientSideApi } from 'api/clientSideApi'
import { currentEventSelector } from 'ReduxState/reducers/currentEvent'
import { format } from 'date-fns'
import { getGeolocation } from 'utils/getGeolocation'
import ons from 'onsenui'
import { previousCheckInNotification } from './previousCheckInNotification'
import { useAppSelector } from 'ReduxState/hooks'
import { useCallback } from 'react'
import { usePouch } from 'use-pouchdb'
import { userSelector } from 'ReduxState/reducers/user'

export const useCheckIn = (onComplete?: () => void) => {
  const db = usePouch()
  const { eventDescription, activeBase, base } =
    useAppSelector(currentEventSelector)
  const { reportingUsername } = useAppSelector(userSelector)
  const {
    pRef = 'Team',
    geolocationInUse,
    geolocationPaid,
    trackingUrl,
  } = eventDescription || {}

  const { baseGeolocation = false, isTransport, baseMaxScore } = base || {}
  const isCheckpoint = !baseMaxScore && !isTransport
  const useGeolocation = geolocationInUse && geolocationPaid && baseGeolocation

  return useCallback(
    async (patrol: Log['patrol']) => {
      try {
        const _id = baseLogIdGenerator({ patrol, base: activeBase })
        try {
          const docExists = await db.get<Log>(_id)
          if (!isTransport) {
            await previousCheckInNotification(docExists, pRef)
          }
          return onComplete?.()
        } catch (e) {
          const docExists = (e as Log) || {}
          const d = new Date()
          const timestamp = d.toISOString()
          const doc: Log = {
            ...docExists,
            _id,
            patrol,
            base: activeBase,
            timeIn: timestamp,
            timeOut: timestamp,
            timeWait: 0,
            offRoute: '',
            username: reportingUsername,
            totalScore: undefined,
            timestamp: timestamp,
            editable: true,
            action: isCheckpoint ? LogAction.CHECK_OUT : LogAction.CHECK_IN,
          }
          if (useGeolocation) {
            try {
              const location = await getGeolocation()
              doc.geolocation = {
                lat: location.coords.latitude,
                lon: location.coords.longitude,
                accuracy: location.coords.accuracy,
              }
            } catch (e) {
              console.log('geolocation failed')
            }
          }
          await db.put(doc)
          if (useGeolocation && trackingUrl) {
            await clientSideApi.sendViaOsmAnd({ pRef, log: doc, trackingUrl })
          }

          onComplete?.()
          ons.notification.toast(
            `<strong>${pRef} ${patrol}</strong> logged as checked into Base ${activeBase} @ ${format(
              d,
              'HH:mm:ss'
            )}`,
            { timeout: 3500, animation: 'fall' }
          )
        }
      } catch (e) {
        const err = e as Error
        onComplete?.()
        ons.notification.alert(err.message)
      }
    },
    [activeBase, pRef, db, onComplete, useGeolocation, reportingUsername]
  )
}
