import { EventCard } from './EventCard/EventCard'
import { EventDescription } from 'types/EventDescription'
import { Navigator } from 'react-onsenui'
import React from 'react'

export type CardMapperProps = {
  events: EventDescription[]
  navigator: Navigator
}

export const CardMapper = ({ events = [], navigator }: CardMapperProps) => {
  return (
    <>
      {events.map((evt) => (
        <EventCard
          key={`${evt.eventName}${evt.dateStart}${evt.dateEnd}`}
          {...evt}
          navigator={navigator}
        />
      ))}
    </>
  )
}
