import { Button } from 'components/Button/Button'
import { EventDescription } from 'types/EventDescription'
import { LoggingDialogType } from '../LoggingDialog/LoggingDialog'
import React from 'react'
import styles from './ActionButtons.module.css'

export type ActionButtonsProps = {
  pRef: EventDescription['pRef']
  baseTerm: string
  baseNo: number
  setDialog: React.Dispatch<React.SetStateAction<LoggingDialogType>>
  isCheckpoint?: boolean
}

export const ActionButtons = ({
  pRef,
  baseTerm,
  baseNo,
  setDialog,
  isCheckpoint,
}: ActionButtonsProps) => {
  return (
    <>
      <Button onClick={() => setDialog('checkIn')}>
        Check In {pRef} to {baseTerm} {baseNo}
      </Button>
      {!isCheckpoint && (
        <Button
          className={styles.checkOutButton}
          onClick={() => setDialog('checkOut')}
        >
          Check Out {pRef} from {baseTerm} {baseNo}
        </Button>
      )}
      <Button modifier="outline" onClick={() => setDialog('seen')}>
        <div style={{ display: 'grid', justifyContent: 'center' }}>
          <div>{pRef} Seen</div>
          <small style={{ marginTop: '-12px' }}>
            (But not checking into {baseTerm})
          </small>
        </div>
      </Button>
    </>
  )
}
