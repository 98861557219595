import { Fab, Icon, SpeedDial, SpeedDialItem } from 'react-onsenui'
import React, { useCallback } from 'react'
import {
  adminFunctionsSelector,
  resetSelectedLogs,
} from 'ReduxState/reducers/adminFunctions'
import { useAppDispatch, useAppSelector } from 'ReduxState/hooks'

import { Log } from 'types/Log'
import clsx from 'clsx'
import fabStyles from 'components/Fab.module.css'
import ons from 'onsenui'
import { usePouch } from 'use-pouchdb'

export type AdminSpeedDialProps = {}

export const AdminSpeedDial = ({}: AdminSpeedDialProps) => {
  const { selectedLogs } = useAppSelector(adminFunctionsSelector)
  const dispatch = useAppDispatch()
  const db = usePouch()

  const onAdminLock = useCallback(async () => {
    await Promise.all(
      Object.keys(selectedLogs).map(async (id) => {
        const doc = await db.get<Log>(id)
        if (doc.editable) {
          return db.put({ ...doc, editable: false })
        }
      })
    )
    dispatch(resetSelectedLogs())
  }, [selectedLogs, dispatch, db])

  const onAdminUnlock = useCallback(async () => {
    await Promise.all(
      Object.keys(selectedLogs).map(async (id) => {
        const doc = await db.get<Log>(id)
        if (!doc.editable) {
          return db.put({ ...doc, editable: true })
        }
      })
    )
    dispatch(resetSelectedLogs())
  }, [selectedLogs, dispatch, db])

  const onAdminDelete = useCallback(async () => {
    const confirm: 0 | 1 = (await ons.notification.confirm(
      'Are you sure you want to delete these logs?'
    )) as any
    if (confirm) {
      await Promise.all(
        Object.keys(selectedLogs).map(async (id) => {
          const doc = await db.get<Log>(id)
          if (doc) {
            await db.remove(doc)
          }
        })
      )
    }
    dispatch(resetSelectedLogs())
  }, [selectedLogs, dispatch, db])

  const isHidden = Object.keys(selectedLogs).length === 0
  return (
    <SpeedDial position="bottom right" direction="up">
      <Fab
        className={clsx({
          [fabStyles.hideFab]: isHidden,
        })}
      >
        <Icon icon="md-edit" />
      </Fab>
      <SpeedDialItem
        id="adminLock"
        className={clsx({
          [fabStyles.hideFab]: isHidden,
        })}
        onClick={onAdminLock}
      >
        <Icon icon="md-lock" />
      </SpeedDialItem>
      <SpeedDialItem
        id="adminUnlock"
        className={clsx({
          [fabStyles.hideFab]: isHidden,
        })}
        onClick={onAdminUnlock}
      >
        <Icon icon="md-lock-open" />
      </SpeedDialItem>
      <SpeedDialItem
        id="adminDelete"
        className={clsx({
          [fabStyles.hideFab]: isHidden,
        })}
        onClick={onAdminDelete}
      >
        <Icon icon="md-delete" />
      </SpeedDialItem>
    </SpeedDial>
  )
}
