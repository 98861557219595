import { Form, Formik } from 'formik'
import { Navigator, Page } from 'react-onsenui'
import {
  currentEventSelector,
  setActiveBase,
} from 'ReduxState/reducers/currentEvent'
import { object, string } from 'yup'
import { updateBaseUser, userSelector } from 'ReduxState/reducers/user'
import { useAppDispatch, useAppSelector } from 'ReduxState/hooks'

import { AppToolbar } from 'components/AppToolbar/AppToolbar'
import { Button } from 'components/Button/Button'
import { FormikInput } from 'components/FormikInput/FormikInput'
import { FormikSelect } from 'components/FormikSelect/FormikSelect'
import React from 'react'
import { Routes } from 'constants/routes'
import ons from 'onsenui'

export type EventSignInPageProps = {
  navigator: Navigator
}

export const EventSignInPage = ({ navigator }: EventSignInPageProps) => {
  const dispatch = useAppDispatch()
  const { eventDescription } = useAppSelector(currentEventSelector)
  const { reportingUsername = '' } = useAppSelector(userSelector)
  if (!eventDescription) {
    return <Page>Loading...</Page>
  }
  const {
    eventName,
    eventDescription: description,
    bases,
    passwordProtectLogs,
    adminPassword,
  } = eventDescription
  return (
    <Page
      id={Routes.EVENT_SIGN_IN_PAGE}
      renderToolbar={() => (
        <AppToolbar
          title={eventName}
          navigator={navigator}
          hideSearch
          hideMessaging
        />
      )}
    >
      <div className="pageDiv marginTop32px">
        <div>
          <img
            src="img/largeLogo.svg"
            alt="The logo for Checkpoint Live: Checkpoint Live, track together"
            className="loginLogo"
            id="loginEventLogo"
          />
        </div>
        <Formik
          initialValues={{ reportingUsername, code: '', baseNo: '' }}
          validationSchema={object({
            reportingUsername: string()
              .min(3, 'Please enter a longer name')
              .required('Please enter a name'),
            code: passwordProtectLogs
              ? string().required('A code is required to enter a checkpoint')
              : string(),
            baseNo: passwordProtectLogs
              ? string()
              : string().required('Please select a checkpoint'),
          })}
          onSubmit={async (
            { reportingUsername, code, baseNo },
            { setSubmitting }
          ) => {
            try {
              dispatch(updateBaseUser(reportingUsername))
              let activeBase: number = baseNo ? parseInt(baseNo) : -1

              if (passwordProtectLogs) {
                const base = bases.find(
                  ({ basePassword }) =>
                    basePassword.toLowerCase() === code.toLowerCase()
                )
                if (!base) {
                  throw new Error(`Code does not match a checkpoint's password`)
                }
                activeBase = base.baseNo
              } else if (activeBase === 0) {
                const promptInput: string = (await ons.notification.prompt({
                  title: 'Enter Admin Code',
                  messageHTML:
                    'To enter the admin area please enter the admin code provided to you by the event organisers:',
                  cancelable: true,
                })) as any
                if (promptInput !== adminPassword) {
                  throw new Error(`Incorrect admin code`)
                }
              }
              dispatch(setActiveBase(activeBase))
              if (activeBase === 0) {
                return navigator.pushPage({ title: Routes.ADMIN })
              }
              return navigator.pushPage({ title: Routes.BASE_PAGE })
            } catch (e) {
              const err = e as Error
              await ons.notification.alert(err.message)
              setSubmitting(false)
            }
          }}
        >
          {({ isSubmitting }) => {
            return (
              <Form id="loginForm">
                <p className="marginTop" id="loginWelcome">
                  Please enter your name and code below:
                </p>
                <p>
                  <FormikInput
                    name="reportingUsername"
                    modifier="underbar"
                    placeholder="Name"
                    float
                    className="fullWidthInput"
                  />
                </p>
                <p id="betweenNameAndBase">
                  This is the short code provided to you by the event's
                  organisers.
                </p>
                <p id="baseCodeParagraph">
                  {passwordProtectLogs ? (
                    <FormikInput
                      name="code"
                      modifier="underbar"
                      placeholder="Code"
                      float
                      className="fullWidthInput"
                    />
                  ) : (
                    <FormikSelect
                      name="baseNo"
                      className="fullWidthInput"
                      options={[
                        { text: 'Select a Base', value: '-1' },
                        ...bases.map(({ baseNo, baseName }) => ({
                          text: baseName,
                          value: `${baseNo}`,
                        })),
                      ]}
                    />
                  )}
                </p>
                <p className="caption" id="loginCodeCaption">
                  (Each code is individual to each checkpoint)
                </p>
                <p className="txtCenter marginTop">
                  <Button
                    disabled={isSubmitting}
                    isLarge
                    className="loginButton secondaryColorButton"
                    type="submit"
                  >
                    Go to checkpoint
                  </Button>
                </p>
              </Form>
            )
          }}
        </Formik>

        <div>
          <h2
            className="txtLeft bold borderBottom sentanceCase"
            id="loginEventDescriptionTitle"
          >
            Event Details
          </h2>

          <p style={{ whiteSpace: 'pre-wrap' }}>{description}</p>
        </div>
      </div>
    </Page>
  )
}
