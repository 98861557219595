import { Log, LogAction } from 'types/Log'
import React, { MouseEventHandler } from 'react'
import { format, isToday } from 'date-fns'

import { Icon } from 'react-onsenui'
import clsx from 'clsx'
import styles from './TableLogRow.module.css'

export type TableLogRowProps = Log & {
  onClick?: MouseEventHandler
  selected?: boolean
  baseView?: boolean
  isTransport?: boolean
}

export const TableLogRow = ({
  base,
  patrol,
  timeIn,
  timeOut,
  timeWait,
  offRoute,
  username: recordedBy,
  totalScore,
  geolocation,
  editable,
  action,
  onClick,
  selected,
  baseView,
  isTransport,
}: TableLogRowProps) => {
  return (
    <tr
      className={clsx({
        [styles.lockedLog]: !editable,
        [styles.offRoute]: offRoute && !baseView,
        [styles.tableRowSelected]: selected,
        [styles.atBase]: action === LogAction.CHECK_IN,
      })}
      onClick={onClick}
    >
      {!baseView && (
        <>
          <td>
            <div className={styles.iconContainer}>
              <div className={styles.padlock} />
              <Icon
                icon="fa-trophy"
                className={
                  totalScore ? styles.iconActive : styles.iconNotActive
                }
                size={16}
              />
              <Icon
                icon={geolocation ? 'md-pin' : 'md-pin-off'}
                className={
                  geolocation ? styles.iconActive : styles.iconNotActive
                }
                size={16}
              />
            </div>
          </td>

          <td>
            <strong>{base}</strong>
          </td>
        </>
      )}
      <td>
        <strong>{patrol}</strong>
      </td>
      {(!baseView || isTransport) && (
        <td>
          {format(
            new Date(timeIn),
            isToday(new Date(timeIn)) ? 'HH:mm' : 'dd/MM/yy HH:mm'
          )}
        </td>
      )}
      <td>
        {action === LogAction.CHECK_IN
          ? 'on site'
          : timeOut &&
            format(
              new Date(timeOut),
              isToday(new Date(timeOut)) ? 'HH:mm' : 'dd/MM/yy HH:mm'
            )}
      </td>
      {!baseView && <td>{timeWait}</td>}
      {!baseView && (
        <td>
          {!!offRoute && (
            <Icon icon="fa-check" size={12} style={{ color: '#2a2a2a' }} />
          )}
        </td>
      )}
      {!isTransport && <td>{totalScore}</td>}
      <td>{recordedBy}</td>
      <td style={{ textTransform: 'capitalize' }}>{action}</td>
      {!baseView && <td className="editable">{editable ? 'yes' : 'no'}</td>}
    </tr>
  )
}
