import { Form, Formik } from 'formik'

import { Button } from 'components/Button/Button'
import { FormikInput } from 'components/FormikInput/FormikInput'
import { Navigator } from 'react-onsenui'
import React from 'react'
import { onLoginSubmit } from './onLoginSubmit'
import { useAppDispatch } from 'ReduxState/hooks'

export type LoginFormProps = { navigator: Navigator }

export const LoginForm = ({ navigator }: LoginFormProps) => {
  const dispatch = useAppDispatch()
  return (
    <Formik
      initialValues={{ username: '', password: '' }}
      onSubmit={(values) => onLoginSubmit(values, navigator, dispatch)}
    >
      {({ submitForm }) => {
        return (
          <Form
            id="signInForm"
            className="txtCenter"
            style={{
              display: 'grid',
              gap: '1rem',
              margin: 'auto',
              maxWidth: '300px',
            }}
          >
            <FormikInput
              autoFocus
              name="username"
              type="string"
              label="Username"
              placeholder="Username/email"
              className="input70perWidth marginTop32px"
            />

            <FormikInput
              name="password"
              placeholder="Password"
              label="Password"
              type="password"
              className="input70perWidth"
            />

            <Button
              type="submit"
              isLarge
              className="signInButton primaryColorButton input70perWidth"
            >
              Sign In
            </Button>
          </Form>
        )
      }}
    </Formik>
  )
}
