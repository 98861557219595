import React, { KeyboardEventHandler } from 'react'

import { useField } from 'formik'

export type ContentEditableDivProps = {
  name: string
  style?: React.CSSProperties
  className?: string
  placeholder?: string
  id?: string
  autoFocus?: boolean
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>
}

export const FormikContentEditableDiv = ({
  name,
  className,
  placeholder,
  onKeyDown,
  ...props
}: ContentEditableDivProps) => {
  const [field, , helpers] = useField(name)
  const divRef = React.useRef<HTMLDivElement>(null)
  const [caretPosition, setCaretPosition] = React.useState<number | null>(null)

  const handleChange = () => {
    if (divRef.current) {
      helpers.setValue(divRef.current.innerText)
      setCaretPosition(getCaretPosition())
    }
  }

  const getCaretPosition = () => {
    const selection = window.getSelection()
    if (selection && selection.rangeCount > 0) {
      const range = selection.getRangeAt(0)
      const clonedRange = range.cloneRange()
      clonedRange.selectNodeContents(divRef.current as Node)
      clonedRange.setEnd(range.endContainer, range.endOffset)
      return clonedRange.toString().length
    }
    return 0
  }

  const handlePaste = (event: React.ClipboardEvent<HTMLDivElement>) => {
    event.preventDefault()
    const pastedText = event.clipboardData.getData('text')
    document.execCommand('insertHTML', false, pastedText)
    handleChange()
  }

  React.useEffect(() => {
    try {
      if (caretPosition !== null && divRef.current) {
        const selection = window.getSelection()
        if (selection) {
          const range = document.createRange()
          if (!divRef.current.childNodes[0]) {
            return
          }
          range.setStart(divRef.current.childNodes[0], caretPosition)
          range.collapse(true)
          selection.removeAllRanges()
          selection.addRange(range)
        }
        setCaretPosition(null)
      }
    } catch (e) {
      console.log(e)
    }
  }, [caretPosition])

  return (
    <>
      <div
        {...props}
        ref={divRef}
        contentEditable
        onBlur={handleChange}
        onPaste={handlePaste}
        onInput={handleChange}
        onKeyDown={onKeyDown}
        dangerouslySetInnerHTML={{ __html: field.value }}
      />
      {!field.value && (
        <div
          id="messageInputPlaceholder"
          className="placeholder text-input--material__label"
        >
          Type a message
        </div>
      )}
    </>
  )
}
