import React, { useMemo } from 'react'

import { LastSeenRow } from './LastSeenRow/LastSeenRow'
import { Log } from 'types/Log'
import { Page } from 'react-onsenui'

export type LastSeenPageProps = {
  docs: Log[]
}

export const LastSeenPage = ({ docs }: LastSeenPageProps) => {
  const lastSeenRows = useMemo(() => {
    return Array.from(
      docs.reduce((acc, log) => {
        if (acc.has(log.patrol)) {
          return acc
        }
        acc.set(log.patrol, log)
        return acc
      }, new Map<string, Log>())
    ).reverse()
  }, [docs])

  return (
    <Page id="lastSeenPage">
      <table className="data-table logTable">
        <thead>
          <tr>
            <th className="txtCenter participantRef">Team</th>
            <th className="txtCenter">Last Seen</th>
            <th className="txtCenter">At ChkPt</th>

            <th className="txtCenter">On/Off Route</th>
            <th className="txtCenter">Recorded by</th>
          </tr>
        </thead>
        <tbody id="lastSeenTable">
          {lastSeenRows.map(([key, lastLog]) => (
            <LastSeenRow key={key} {...lastLog} />
          ))}
        </tbody>
      </table>
    </Page>
  )
}
