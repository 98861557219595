import './bootstrap.min.css'
import 'onsenui/css/onsenui.css'
import 'onsenui/css/onsen-css-components.css'
import './index.css'

import App from './App'
import PouchDB from 'pouchdb-browser'
import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import pouchFind from 'pouchdb-find'
import { register } from './registerServiceWorker'
import reportWebVitals from './reportWebVitals'
import { store } from 'ReduxState/store'

PouchDB.plugin(pouchFind)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  // <React.StrictMode>
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>
  // </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
register()
