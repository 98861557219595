export const baseLogIdGenerator = ({
  patrol,
  base,
  isTransport,
}: {
  patrol: number | string
  base: number
  isTransport?: boolean
}) => {
  if (!isTransport) {
    return `${patrol}_base_${base}`
  }
  const d = new Date()
  return `${patrol}_transport_${base}_${d.getTime()}`
}

export const seenLogIdGenerator = ({
  patrol,
  base,
}: {
  patrol: number | string
  base: number
}) => {
  const d = new Date()

  return `${baseLogIdGenerator({ patrol, base })}_${d.getTime()}`
}

export const messageLogIdGenerator = () => `message-${new Date().toISOString}`
