import { Log, LogAction } from 'types/Log'

import { EventDescription } from 'types/EventDescription'
import { format } from 'date-fns'
import ons from 'onsenui'
import { previousCheckOut } from './loggingErrorMessages'

export const previousCheckInNotification = async (
  docExists: Log,
  pRef: EventDescription['pRef']
) => {
  if (docExists) {
    let messageHTML = ''
    if (docExists.action === LogAction.CHECK_OUT) {
      messageHTML = previousCheckOut(docExists, pRef)
    } else {
      messageHTML = `<p>This ${pRef} has been checked in previously at ${format(
        new Date(docExists.timeIn),
        'HH:mm:ss'
      )}.</p>
      <p>This action will change the check in time to now.</p>
      <b>Are you sure you want to do this?</b>`
    }
    // todo: finish what happens if a log exists
    const confirmation: 1 | 0 = (await ons.notification.confirm({
      title: `${pRef} ${docExists.patrol} previously checked in`,
      messageHTML,
    })) as any
    if (confirmation === 1) {
      throw docExists
    }
  }
}
