import { Fab, Icon } from 'react-onsenui'
import { Form, Formik } from 'formik'
import React, { MouseEventHandler } from 'react'

import { FormikContentEditableDiv } from 'components/FormikContentEditable/FormikContentEditable'
import { MessageBadge } from 'components/MessageBadge/MessageBadge'
import { MessageLog } from 'types/MessageLog'
import { currentEventSelector } from 'ReduxState/reducers/currentEvent'
import ons from 'onsenui'
import styles from './SendMessageToolbar.module.css'
import { useAppSelector } from 'ReduxState/hooks'
import { usePouch } from 'use-pouchdb'
import { userSelector } from 'ReduxState/reducers/user'

export type SendMessageToolbarProps = {
  onScrollClick: MouseEventHandler<HTMLDivElement>
  showScrollButton?: boolean
  notSeenMessages?: number
}

export const SendMessageToolbar = ({
  onScrollClick,
  showScrollButton,
  notSeenMessages,
}: SendMessageToolbarProps) => {
  const db = usePouch()
  const { reportingUsername } = useAppSelector(userSelector)
  const { activeBase } = useAppSelector(currentEventSelector)

  return (
    <div id="messageTools">
      <Formik
        initialValues={{ message: '' }}
        onSubmit={async ({ message }, { resetForm }) => {
          try {
            const msg = message.trim()
            if (msg.length === 0) {
              return
            }
            const time = new Date().toISOString()
            await db.put<MessageLog>({
              _id: `message-${time}`,
              message: msg,
              username: reportingUsername,
              time,
              from: activeBase,
            })
            resetForm()
          } catch (e) {
            const err = e as Error
            ons.notification.alert(err.message)
          }
        }}
      >
        {({ submitForm, isSubmitting }) => {
          return (
            <Form className="messageInputArea">
              {showScrollButton && (
                <div id="scrollToBottomFab">
                  <Fab
                    ripple
                    className="secondaryFab fab--mini"
                    onClick={onScrollClick}
                  >
                    <Icon icon="fa-angle-double-down" />
                  </Fab>
                  {!!notSeenMessages && (
                    <MessageBadge className={styles.messageBadge}>
                      {notSeenMessages}
                    </MessageBadge>
                  )}
                </div>
              )}
              <div className="messageInputContainer">
                <FormikContentEditableDiv
                  placeholder="Type a message"
                  name="message"
                  id="messageInput"
                  autoFocus
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' && !e.shiftKey) {
                      e.preventDefault()
                      submitForm()
                    }
                  }}
                />
              </div>

              <Fab
                ripple
                id="sendMessage"
                modifier="mini"
                onClick={(e) => {
                  submitForm()
                }}
                disabled={isSubmitting}
              >
                <i className="zmdi zmdi-mail-send"></i>
              </Fab>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}
