import React from 'react'

export type LeaderboardRowProps = {
  value: number
  teamNo: string | number
  position: number
}

export const LeaderboardRow = ({
  position,
  value,
  teamNo,
}: LeaderboardRowProps) => {
  return (
    <tr>
      <td>{position}</td>
      <td>{teamNo}</td>
      <td>{value}</td>
    </tr>
  )
}
