/**
 * Inspiration taken from:
 * https://github.com/apollographql/apollo-server/blob/main/packages/apollo-datasource-rest/src/RESTDataSource.ts
 */
type OutGoingBody =
  | Record<string, any>
  | string
  | number
  | boolean
  | undefined
  | FormData
type AdditionalHeaders = Record<string, any>
type PostOrPutOptions = {
  url: string
  body?: OutGoingBody
  additionalHeaders?: AdditionalHeaders
}
type FetchError = {
  message: string
  extensions: {
    url?: string
    status?: number
    statusText?: string
    body?: any
  }
}

export class FetchWrapper {
  baseURL?: string
  constructor(
    baseUrl: string | undefined = process.env.REACT_APP_PUBLIC_API_BASE_URL
  ) {
    this.baseURL = baseUrl
  }
  protected resolveURL = (path: string) => {
    if (path.startsWith('http')) {
      // checks for external urls
      return path
    }
    if (path.startsWith('/')) {
      path = path.slice(1)
    }
    const baseURL = this.baseURL
    if (baseURL) {
      const normalizedBaseURL = baseURL.endsWith('/')
        ? baseURL
        : baseURL.concat('/')
      return `${normalizedBaseURL}${path}`
    } else {
      return path
    }
  }
  protected didReceiveResponse = async (response: Response) => {
    if (response.ok) {
      return this.parseBody(response)
    } else {
      throw await this.errorFromResponse(response)
    }
  }

  protected errorFromResponse = async (
    response: Response
  ): Promise<FetchError> => {
    const message = `${response.status}: ${response.statusText}`

    let error = { message, extensions: {} }
    // if (response.status === 401) {
    //   error = new AuthenticationError(message)
    // } else if (response.status === 403) {
    //   error = new ForbiddenError(message)
    // } else {
    //   error = new ApolloError(message)
    // }

    const body = await this.parseBody(response)

    Object.assign(error.extensions, {
      response: {
        url: response.url,
        status: response.status,
        statusText: response.statusText,
        body,
      },
    })

    return error
  }

  protected parseBody = async (response: Response): Promise<any> => {
    const contentType = response.headers.get('Content-Type')
    const contentLength = response.headers.get('Content-Length')

    if (
      // As one might expect, a "204 No Content" is empty! This means there
      // isn't enough to `JSON.parse`, and trying will result in an error.
      response.status !== 204 &&
      contentLength !== '0' &&
      contentType &&
      (contentType.startsWith('application/json') ||
        contentType.endsWith('+json'))
    ) {
      return response.json()
    } else {
      return response.text()
    }
  }

  get = async (url: string, additionalHeaders: AdditionalHeaders = {}) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        ...additionalHeaders,
      },
    }
    return fetch(this.resolveURL(url), requestOptions).then(
      this.didReceiveResponse
    )
  }

  getImage = async (url: string, additionalHeaders: AdditionalHeaders = {}) => {
    const requestOptions = {
      method: 'GET',
      headers: {
        ...additionalHeaders,
      },
    }
    return fetch(this.resolveURL(url), requestOptions).then((response) => {
      if (response.ok) {
        return response.blob()
      } else {
        throw this.errorFromResponse(response)
      }
    })
  }

  post = async ({
    url,
    body = {},
    additionalHeaders = {},
  }: PostOrPutOptions) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...additionalHeaders,
      },
      body: JSON.stringify(body),
    }
    return fetch(this.resolveURL(url), requestOptions).then(
      this.didReceiveResponse
    )
  }

  upload = async ({
    url,
    body,
    additionalHeaders,
  }: {
    url: string
    body: FormData
    // additional headers should never contain the content-type for upload or the boundary will not be set and the file cannot be read
    additionalHeaders?: AdditionalHeaders
  }) => {
    const requestOptions = {
      method: 'POST',
      headers: {
        ...additionalHeaders,
      },
      body,
    }
    return fetch(this.resolveURL(url), requestOptions).then(
      this.didReceiveResponse
    )
  }

  put = async (
    url: string,
    { body = {}, additionalHeaders = {} }: PostOrPutOptions
  ) => {
    const requestOptions = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',

        ...additionalHeaders,
      },
      body: JSON.stringify(body),
    }
    return fetch(this.resolveURL(url), requestOptions).then(
      this.didReceiveResponse
    )
  }

  delete = async (url: string, additionalHeaders: AdditionalHeaders = {}) => {
    const requestOptions = {
      method: 'DELETE',
      headers: {
        ...additionalHeaders,
      },
    }
    return fetch(this.resolveURL(url), requestOptions).then(
      this.didReceiveResponse
    )
  }
}
