import React, { PropsWithChildren } from 'react'

import clsx from 'clsx'
import styles from './MessageBadge.module.css'

export type MessageBadgeProps = PropsWithChildren<{ className?: string }>

export const MessageBadge = ({ children, className }: MessageBadgeProps) => {
  return <div className={clsx(styles.messageBadge, className)}>{children}</div>
}
