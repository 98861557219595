import { Button, Icon, Navigator } from 'react-onsenui'
import React, { useState } from 'react'
import {
  changeEvent,
  loadEventDescription,
} from 'ReduxState/reducers/currentEvent'

import { EventDescription } from 'types/EventDescription'
import { Routes } from 'constants/routes'
import clsx from 'clsx'
import { useAppDispatch } from 'ReduxState/hooks'

export type EventCardProps = EventDescription & { navigator: Navigator }

export const EventCard = ({
  navigator,
  ...eventDescription
}: EventCardProps) => {
  const { dbName, eventName, eventDescription: description } = eventDescription
  const dispatch = useAppDispatch()
  const [showDescription, toggleShowDescription] = useState(false)
  return (
    <div className="card shadow--2dp" style={{}}>
      <h4 className="material-card__title">{eventName}</h4>
      <div className="material-card__actions material-card--border">
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <Button
              modifier="quiet"
              className="secondaryColor"
              onClick={() => {
                dispatch(changeEvent(dbName))
                dispatch(loadEventDescription(eventDescription))
                navigator.pushPage({ title: Routes.EVENT_SIGN_IN_PAGE })
              }}
            >
              Enter Event
            </Button>
            <Button modifier="quiet" className="secondaryColor">
              Info
            </Button>
          </div>
          {description && (
            <Button
              onClick={() => toggleShowDescription(!showDescription)}
              modifier="quiet"
              ripple
              className={clsx(
                'cardIconButton button--material button--material--flat',
                { rotate270: !showDescription }
              )}
            >
              <Icon icon="fa-chevron-down" />
            </Button>
          )}
        </div>
      </div>
      <div
        className={clsx('material-card__supporting-text', {
          hide: !showDescription || !description,
        })}
      >
        <p style={{ whiteSpace: 'pre-wrap' }}>{description}</p>
      </div>
    </div>
  )
}
