import { useEffect } from 'react'
import { usePouch } from 'use-pouchdb'

export const useCheckForLeaderBoardIndex = () => {
  const db = usePouch()

  useEffect(() => {
    const checkForLeaderBoardIndex = async () => {
      try {
        await db.get('_design/leaderboardIndex')
      } catch (e) {
        const leaderboardIndex = {
          _id: '_design/leaderboardIndex',
          views: {
            leaderboardIndex: {
              map: `function (doc) {
                if ('patrol' in doc && typeof doc.patrol === 'string') {
                  emit(doc.patrol, parseInt(doc.totalScore) || 0)
                }
              }`,
              reduce: '_sum',
            },
          },
        }
        return db.put(leaderboardIndex)
      }
    }
    checkForLeaderBoardIndex()
  }, [db])
}
