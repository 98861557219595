import { Button, Navigator, Page } from 'react-onsenui'
import React, { useEffect, useState } from 'react'
import { isBefore, startOfDay } from 'date-fns'
import { useAppDispatch, useAppSelector } from 'ReduxState/hooks'

import { AppToolbar } from 'components/AppToolbar/AppToolbar'
import { EventDescription } from 'types/EventDescription'
import { EventGroup } from './EventGroup/EventGroup'
import { getEventDescription } from 'pouchdb/getEventDescription'
import { toggleLoader } from 'ReduxState/reducers/layout'
import { userSelector } from 'ReduxState/reducers/user'

type EventStates = {
  ongoing: EventDescription[]
  today: EventDescription[]
  upcoming: EventDescription[]
  past: EventDescription[]
}

const initialState: EventStates = {
  ongoing: [],
  today: [],
  upcoming: [],
  past: [],
}

export type EventSelectionPageProps = { navigator: Navigator }

export const EventSelectionPage = ({ navigator }: EventSelectionPageProps) => {
  const { dbs } = useAppSelector(userSelector)
  const dispatch = useAppDispatch()
  const [{ ongoing, today, upcoming, past }, setEvents] =
    useState<EventStates>(initialState)

  useEffect(() => {
    // get event descriptions
    const setAsyncEvents = async () => {
      dispatch(toggleLoader(true))
      const evts = await Promise.all(dbs.map(getEventDescription))
      const dateNow = new Date()
      const todayStart = startOfDay(dateNow)
      const todayEnd = startOfDay(dateNow)

      const categorisedEvents = evts.reduce(
        (acc, evt) => {
          const { dateStart, dateEnd } = evt
          const evtStart = new Date(dateStart)
          const evtEnd = new Date(dateEnd)
          if (isBefore(evtStart, dateNow) && isBefore(dateNow, evtEnd)) {
            // ongoing
            acc.ongoing.push(evt)
          } else if (
            isBefore(todayStart, evtStart) &&
            isBefore(evtStart, todayEnd)
          ) {
            // today
            acc.ongoing.push(evt)
          } else if (isBefore(evtEnd, dateNow)) {
            // past
            acc.past.push(evt)
          } else {
            acc.upcoming.push(evt)
          }
          return acc
        },
        {
          ongoing: [],
          today: [],
          upcoming: [],
          past: [],
        } as EventStates
      )
      setEvents(categorisedEvents)
      dispatch(toggleLoader(false))
    }
    setAsyncEvents()
  }, [dbs, dispatch])

  return (
    <Page
      id="eventSelectionPage"
      className="cardBackground"
      renderToolbar={() => (
        <AppToolbar
          navigator={navigator}
          title="Select an Event"
          hideMessaging
          hideSearch
        />
      )}
    >
      <div className="pageDiv">
        <div id="createNewEvent">
          <Button
            id="createNewEventButton"
            modifier="large"
            className="secondaryColorButton"
          >
            Create New Event
          </Button>
        </div>

        <EventGroup events={ongoing} navigator={navigator}>
          Ongoing Events
        </EventGroup>
        <EventGroup events={today} navigator={navigator}>
          Today's Upcoming Events
        </EventGroup>
        <EventGroup events={upcoming} navigator={navigator}>
          Upcoming Events
        </EventGroup>
        <EventGroup events={past} navigator={navigator}>
          Past Events
        </EventGroup>
      </div>
    </Page>
  )
}
