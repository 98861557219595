import { Button, Icon } from 'react-onsenui'
import React, { PropsWithChildren } from 'react'

import { MessageLog } from 'types/MessageLog'
import clsx from 'clsx'
import { currentEventSelector } from 'ReduxState/reducers/currentEvent'
import { format } from 'date-fns'
import styles from './Message.module.css'
import { useAppSelector } from 'ReduxState/hooks'

export type MessageProps = PropsWithChildren<
  Omit<MessageLog, 'from' | 'message' | '_id'> & {
    msgContinued?: boolean
    msgOut?: boolean
    onRemove?: () => void
    from: 'HQ' | number
  }
>

export const Message = ({
  children,
  msgContinued,
  msgOut,
  from,
  username,
  time,

  onRemove,
}: MessageProps) => {
  const { activeBase } = useAppSelector(currentEventSelector)
  return (
    <div className={clsx(styles.msg, { [styles.msgContinued]: msgContinued })}>
      {activeBase === 0 && (
        <Button
          modifier="quiet"
          type="button"
          className={clsx(styles.deleteButton, { [styles.myBase]: msgOut })}
          onClick={onRemove}
        >
          <Icon icon="fa-trash" size={12} />
        </Button>
      )}
      <div
        className={clsx(styles.bubble, {
          [styles['message-out']]: msgOut,
          [styles['message-in']]: !msgOut,
          [styles.tail]: !msgContinued,
        })}
      >
        <div className={`mgsFrom color-${from}`}>
          <strong>
            {username} @ {from}
          </strong>
        </div>
        <div className={styles['message-text']}>{children}</div>
        <div className={clsx(styles['bubble-text-meta'], styles.msgTimeStamp)}>
          {format(new Date(time), 'HH:mm')}
        </div>
      </div>
    </div>
  )
}
