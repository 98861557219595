import { AdminMap } from './AdminMap/AdminMap'
import { AdminSpeedDial } from './AdminSpeedDial/AdminSpeedDial'
import { AdminTableLog } from './TableLog/AdminTableLog'
import { Log } from 'types/Log'
import { Page } from 'react-onsenui'
import { adminFunctionsSelector } from 'ReduxState/reducers/adminFunctions'
import clsx from 'clsx'
import { useAppSelector } from 'ReduxState/hooks'

export type AllLogsPageProps = { docs: Log[] }

export const AllLogsPage = ({ docs }: AllLogsPageProps) => {
  const { showMap } = useAppSelector(adminFunctionsSelector)

  return (
    <Page id="allLogsPage" renderFixed={() => <AdminSpeedDial />}>
      {showMap && <AdminMap />}

      <div
        id="tab0Container"
        className={clsx('col-xs-12 adminContainer', {
          'col-md-8 col-lg-6 mapShow': showMap,
        })}
      >
        <div id="allLogsCopy">
          <table className="data-table logTable">
            <thead>
              <tr>
                <th>Info</th>
                <th>ChkPt</th>
                <th className="participantRef">Team</th>
                <th>Time in</th>
                <th>Time out</th>
                <th>Wait</th>
                <th>Off route</th>
                <th>Score</th>
                <th>Recorded by</th>
                <th>Action</th>
                <th>edit</th>
              </tr>
            </thead>
            <tbody id="adminLogsTable">
              {docs.map((log) => (
                <AdminTableLog key={log._id} {...log} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Page>
  )
}
