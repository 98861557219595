import { Form, Formik } from 'formik'
import { number, object } from 'yup'

import { Button } from 'components/Button/Button'
import { Dialog } from 'react-onsenui'
import { FormikInput } from 'components/FormikInput/FormikInput'
import React from 'react'
import { currentEventSelector } from 'ReduxState/reducers/currentEvent'
import styles from './LoggingDialog.module.css'
import { useAppSelector } from 'ReduxState/hooks'
import { useCheckIn } from './hooks/useCheckIn'
import { useCheckOut } from './hooks/useCheckOut'
import { useSeen } from './hooks/useSeen'

export type LoggingDialogType = 'checkIn' | 'checkOut' | 'seen' | undefined

export type LoggingDialogProps = {
  dialogType: LoggingDialogType
  onClose: () => void
}

export const LoggingDialog = ({ dialogType, onClose }: LoggingDialogProps) => {
  const { eventDescription, base } = useAppSelector(currentEventSelector)

  const { pRef = 'Team', trackedEntities = 99 } = eventDescription || {}

  const { baseMaxScore } = base || {}
  const onCheckIn = useCheckIn(onClose)

  const onCheckOut = useCheckOut(onClose)

  const onSeen = useSeen(onClose)
  return (
    <div>
      <Formik
        initialValues={{ patrol: '', score: '', timeWait: '' }}
        onSubmit={async (values, { resetForm, setSubmitting }) => {
          const patrol = `${values.patrol}`
          const { score, timeWait } = values
          switch (dialogType) {
            case 'checkIn':
              await onCheckIn(`${patrol}`)
              break
            case 'checkOut':
              await onCheckOut({
                patrol,
                totalScore: +score,
                timeWait: +timeWait || 0,
              })
              break
            default:
              await onSeen({ patrol })
          }
          resetForm()
          setSubmitting(false)
        }}
        validationSchema={object({
          patrol: number()
            .min(
              0,
              `Please enter a ${pRef} number between 1 and ${trackedEntities}`
            )
            .max(
              trackedEntities,
              `Please enter a ${pRef} number between 1 and ${trackedEntities}`
            )
            .required(`Please enter a ${pRef} number`),
          score:
            baseMaxScore && dialogType === 'checkOut'
              ? number()
                  .min(0, 'Score cannot be negative')
                  .max(+baseMaxScore, 'Score cannot exceed the maximum score')
                  .required('Please enter a score')
              : number(),
          timeWait: number().min(
            0,
            'If entering a wait time please enter a positive number'
          ),
        })}
      >
        {({ resetForm, isSubmitting }) => {
          return (
            <Dialog
              isOpen={!!dialogType}
              isCancelable
              onCancel={() => {
                resetForm()
                onClose()
              }}
            >
              <Form
                style={{ padding: '1.5rem', display: 'grid', gap: '1.5rem' }}
              >
                <div>
                  Please enter a {pRef} number
                  {dialogType === 'checkOut' ? ' and score' : ''} below:
                </div>
                {!!dialogType && (
                  <FormikInput
                    autoFocus
                    label={`${pRef} Number`}
                    name="patrol"
                    type="number"
                    modifier="material"
                    placeholder={`${pRef} Number (1 - ${trackedEntities})`}
                    className="fullWidthInput"
                  />
                )}
                {baseMaxScore && dialogType === 'checkOut' && (
                  <>
                    <FormikInput
                      name="score"
                      type="number"
                      label={`${pRef} Total Score`}
                      modifier="underbar"
                      placeholder={`Total Score (max ${baseMaxScore})`}
                      className="fullWidthInput"
                      max={+baseMaxScore}
                      min={0}
                    />

                    <div>
                      <div style={{ margin: '16px 0' }}>
                        If a {pRef} had to wait to begin the base, enter the
                        time waited below:
                      </div>
                      <FormikInput
                        name="timeWait"
                        label="Wait time (optional)"
                        type="number"
                        modifier="underbar"
                        placeholder={`approx: minutes`}
                        className="fullWidthInput"
                        min={0}
                      />
                    </div>
                  </>
                )}
                <div
                  style={{
                    display: 'grid',
                    gap: '1rem',
                    alignItems: 'center',
                    gridTemplateColumns: '1fr 1fr',
                  }}
                >
                  <Button
                    type="button"
                    modifier="outline"
                    className={`fullWidthButton txtCenter ${styles.cancelButton}`}
                    onClick={() => {
                      resetForm()
                      onClose()
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    modifier="outline"
                    className={`fullWidthButton txtCenter ${styles.submitButton}`}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            </Dialog>
          )
        }}
      </Formik>
    </div>
  )
}
