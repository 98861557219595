import { LoginResponseSuccess } from 'api/clientSideApiTypes'
import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { createSlice } from '@reduxjs/toolkit'

const { user } = localStorage
const storedValues = user && JSON.parse(user)

export type UserSliceState = {
  name: string
  dbs: string[]
  verified?: boolean
  verifiedOn?: number
  evtOrganiser?: boolean
  baseRemoteUrl: string
  customerId: string
  reportingUsername: string
  geolocation?: 'accepted' | 'rejected'
}

export const initialState: UserSliceState = {
  name: '',
  dbs: [],
  baseRemoteUrl: '',
  customerId: '',
  reportingUsername: localStorage.reportingUsername || '',
  geolocation: localStorage.geolocation,
  ...storedValues,
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loadCouchDbUser: (
      state,
      {
        payload,
      }: PayloadAction<LoginResponseSuccess['user'] & { baseRemoteUrl: string }>
    ) => {
      const {
        name,
        roles: dbs,
        metadata: {
          verification: { verified, verifiedOn } = {},
          evtOrganiser,
          customerId,
        },
        baseRemoteUrl,
      } = payload
      const stateUser = {
        name,
        dbs,
        verified,
        verifiedOn,
        evtOrganiser,
        customerId,
        baseRemoteUrl,
      }
      localStorage.user = JSON.stringify(stateUser)
      return {
        ...state,
        ...stateUser,
      }
    },
    updateBaseUser: (state, { payload }: PayloadAction<string>) => {
      localStorage.reportingUsername = payload
      state.reportingUsername = payload
    },
    setGeolocationStatus: (
      state,
      { payload }: PayloadAction<'accepted' | 'rejected'>
    ) => {
      localStorage.geolocation = payload
      state.geolocation = payload
    },
  },
})

export const { loadCouchDbUser, updateBaseUser, setGeolocationStatus } =
  userSlice.actions
export const userSelector = (state: RootState) => state[userSlice.name]
