import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { createSlice } from '@reduxjs/toolkit'

export type AdminFunctionsSliceState = {
  selectedLogs: Record<string, boolean>
  showMap?: boolean
}

export const initialState: AdminFunctionsSliceState = {
  selectedLogs: {},
}

export const adminFunctionsSlice = createSlice({
  name: 'adminFunctions',
  initialState,
  reducers: {
    selectLog: (state, { payload }: PayloadAction<string>) => {
      if (state.selectedLogs[payload]) {
        state.selectedLogs = {}
      } else {
        state.selectedLogs = { [payload]: true }
      }
    },
    shiftSelectLog: (state, { payload }: PayloadAction<string>) => {
      if (state.selectedLogs[payload]) {
        delete state.selectedLogs[payload]
      } else {
        state.selectedLogs[payload] = true
      }
    },
    resetSelectedLogs: (state) => {
      state.selectedLogs = {}
    },
    toggleMap: (state, { payload }: PayloadAction<boolean | undefined>) => {
      state.showMap = typeof payload === 'boolean' ? payload : !state.showMap
    },
  },
})

export const { selectLog, shiftSelectLog, toggleMap, resetSelectedLogs } =
  adminFunctionsSlice.actions
export const adminFunctionsSelector = (state: RootState) =>
  state[adminFunctionsSlice.name]
