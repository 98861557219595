import { EventDescription } from 'types/EventDescription'
import PouchDb from 'pouchdb-browser'

export const getEventDescription = async (
  dbName: string
): Promise<EventDescription> => {
  const db = new PouchDb(dbName)
  const doc = await db.get<EventDescription>('eventDescription')
  return doc
}
