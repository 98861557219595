import { AppDispatch } from 'ReduxState/store'
import { Navigator } from 'react-onsenui'
import { Routes } from 'constants/routes'
import { changeAtSymbol } from 'utils/changeAtSymbol'
import { changeEvent } from 'ReduxState/reducers/currentEvent'
import { clientSideApi } from 'api/clientSideApi'
import { loadCouchDbUser } from 'ReduxState/reducers/user'
import ons from 'onsenui'
import { remoteUrl } from 'pouchdb/remoteUrl'
import { replicateOnce } from 'pouchdb/replicateOnce'
import { toggleLoader } from 'ReduxState/reducers/layout'

export const onLoginSubmit = async (
  values: { username: string; password: string },
  navigator: Navigator,
  dispatch: AppDispatch
) => {
  try {
    dispatch(toggleLoader(true))
    const response = await clientSideApi.login(values)

    const { status } = response
    if (status === 401) {
      throw new Error(
        `<p>${response.message}</p><p>Be aware that both your username and password are case sensitive.</p><p>If you haven't signed up but would like to, click the big orange button below.</p>`
      )
    }
    if (status === 200) {
      const { username, password } = values
      const {
        http,
        couchdb,
        user: {
          metadata: { evtOrganiser },
          roles: dbs,
        },
      } = response

      const baseRemoteUrl = remoteUrl({
        username: changeAtSymbol(username),
        password,
        http,
        couchdb,
      })

      dispatch(loadCouchDbUser({ ...response.user, baseRemoteUrl }))

      if (dbs.length) {
        await Promise.all(
          dbs.map(async (event) => {
            try {
              const once = await replicateOnce({
                event,
                remoteUrl: `${baseRemoteUrl}/${event}`,
                docIds: ['eventDescription'],
              })
              return once
            } catch (e) {}
          })
        )

        dispatch(toggleLoader(false))

        if (evtOrganiser || dbs.length > 1) {
          navigator.pushPage({ title: Routes.EVENT_SELECTION_PAGE })
        } else if (dbs.length === 1) {
          dispatch(changeEvent(dbs[0]))
          navigator.pushPage({ title: Routes.EVENT_SIGN_IN_PAGE })
        } else {
          ons.notification.alert(
            'Your event has finished and can no longer be accessed'
          )
        }
      }
    } else {
      throw new Error('<p>Could not communicate with the server</p>')
    }
  } catch (e) {
    dispatch(toggleLoader(false))
    const err = e as Error
    ons.notification.alert({
      title: 'Login Failed',
      messageHTML: err.message,
      cancelable: true,
    })
  } finally {
    dispatch(toggleLoader(false))
  }
}
