import React, { ButtonHTMLAttributes, PropsWithChildren } from 'react'

import clsx from 'clsx'
import styles from './Button.module.css'

export type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  PropsWithChildren<{
    modifier?: 'outline'
    isLarge?: boolean
  }>

export const Button = ({
  children,
  modifier,
  className,
  isLarge,
  type = 'button',
  ...props
}: ButtonProps) => {
  return (
    <button
      type={type}
      className={clsx(
        styles.button,
        { [styles.outline]: modifier === 'outline', [styles.large]: isLarge },
        className
      )}
      {...props}
    >
      {children}
    </button>
  )
}
