import React, { useMemo } from 'react'

import { Navigator } from 'react-onsenui'
import PouchDB from 'pouchdb-browser'
import { Provider } from 'use-pouchdb'
import { Routes } from 'constants/routes'
import { SyncRouteInner } from './SyncRouteInner/SyncRouteInner'
import { currentEventSelector } from 'ReduxState/reducers/currentEvent'
import { useAppSelector } from 'ReduxState/hooks'
import { useGeolocationNotification } from 'hooks/useGeolocationNotification'
// import { useGetEventDescription } from 'hooks/useGetEventDescription'
import { usePouchDBSync } from 'hooks/usePouchDbSync'

// import { useCreatePouchDBLocalIndexes } from 'hooks/useCreatePouchDBLocalIndexes'

export type SyncRoutesProps = {
  route: { title: Routes; hasBackButton?: boolean }
  navigator: Navigator
}

export const SyncRoutes = (props: SyncRoutesProps) => {
  const { currentDb } = useAppSelector(currentEventSelector)
  // useCreatePouchDBLocalIndexes()
  usePouchDBSync()
  // useGetEventDescription()
  useGeolocationNotification()
  const db = useMemo(() => new PouchDB(currentDb), [currentDb])

  return (
    <Provider pouchdb={db}>
      <SyncRouteInner {...props} />
    </Provider>
  )
}
