import { Icon, Navigator, ToolbarButton } from 'react-onsenui'

import { MessageBadge } from 'components/MessageBadge/MessageBadge'
import { MessageLog } from 'types/MessageLog'
import React from 'react'
import { currentEventSelector } from 'ReduxState/reducers/currentEvent'
import { useAllDocs } from 'use-pouchdb'
import { useAppSelector } from 'ReduxState/hooks'

export type MessagingProps = { navigator: Navigator }

export const Messaging = ({ navigator }: MessagingProps) => {
  const { lastMsgId } = useAppSelector(currentEventSelector)
  const { rows = [] } = useAllDocs<MessageLog>({
    include_docs: false,
    endkey: lastMsgId,
    startkey: 'message\ufff0',
    descending: true,
  })

  return (
    <ToolbarButton
      onClick={() => navigator.pushPage({ title: 'messagingPage' })}
    >
      <Icon icon="fa-comments" />
      {rows.length > 1 && (
        <MessageBadge>
          {lastMsgId === 'message' ? rows.length : rows.length - 1}
        </MessageBadge>
      )}
    </ToolbarButton>
  )
}
