import { BaseMetadata, EventDescription } from 'types/EventDescription'

import type { PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../store'
import { createSlice } from '@reduxjs/toolkit'
import { format } from 'date-fns'

export type CurrentEventSliceState = {
  currentDb?: string
  eventDescription?: EventDescription
  activeBase: number
  lastSync?: string
  base?: BaseMetadata
  lastMsgId: string
  teamSearch?: string
}

export const initialState: CurrentEventSliceState = {
  activeBase: +localStorage.activeBase || -1,
  currentDb: localStorage.currentDb,
  lastMsgId: localStorage.lastMsgId || 'message',
}

export const currentEventSlice = createSlice({
  name: 'currentEvent',
  initialState,
  reducers: {
    changeEvent: (state, { payload }: PayloadAction<string | undefined>) => {
      localStorage.currentDb = payload
      state.currentDb = payload
    },
    loadEventDescription: (
      state,
      { payload }: PayloadAction<EventDescription>
    ) => {
      state.eventDescription = payload
    },
    setActiveBase: (state, { payload }: PayloadAction<number>) => {
      localStorage.activeBase = payload
      state.activeBase = payload
    },
    loadCurrentBase: (state, { payload }: PayloadAction<BaseMetadata>) => {
      state.base = payload
    },
    setLastSync: (state) => {
      state.lastSync = format(new Date(), 'HH:mm:ss')
    },
    setLastMsgId: (state, { payload }: PayloadAction<string>) => {
      localStorage.lastMsgId = payload
      state.lastMsgId = payload
    },
    setTeamSearch: (state, { payload }: PayloadAction<string | undefined>) => {
      state.teamSearch = payload
    },
  },
})

export const {
  changeEvent,
  loadEventDescription,
  setActiveBase,
  setLastSync,
  loadCurrentBase,
  setLastMsgId,
  setTeamSearch,
} = currentEventSlice.actions

export const currentEventSelector = (state: RootState) =>
  state[currentEventSlice.name]
