export type Geolocation = {
  lat: number
  lon: number
  accuracy: number
}

export enum LogAction {
  CHECK_IN = 'check in',
  CHECK_OUT = 'check out',
  SEEN = 'seen',
}

export type Log = {
  _id: string
  patrol: string
  base: number
  username: string
  timeIn: string
  timeOut: string
  timeWait: number
  offRoute: boolean | ''
  totalScore?: number
  editable: boolean
  timestamp: string
  geolocation?: Geolocation
  action?: LogAction
}
