import PouchDB from 'pouchdb-browser'

type ReplicateOnceInputs = {
  event: string
  docIds: string[]
  remoteUrl: string
  replicateToRemote?: boolean
}

export const replicateOnce = ({
  event,
  docIds: doc_ids,
  remoteUrl,
  replicateToRemote,
}: ReplicateOnceInputs) => {
  const localPouchDB = new PouchDB(event) //local
  const remoteCouchDB = new PouchDB(remoteUrl) //remote

  var options = {
    doc_ids,
  }
  let replicateFrom
  let replicateTo
  if (replicateToRemote) {
    replicateFrom = localPouchDB
    replicateTo = remoteCouchDB
  } else {
    replicateFrom = remoteCouchDB
    replicateTo = localPouchDB
  }
  return replicateFrom.replicate
    .to(replicateTo, options)
    .then((info) => {
      if (info.docs_written > 0) {
        console.log(`event Description for ${event} updated`)
        return true
      } else {
        console.log(`event Description for ${event} was up to date`)
        return false
      }
    })
    .catch(function (err) {
      console.log('there was an error')
      console.log(err)
      throw err
    })
}
