import {
  Button,
  Checkbox,
  Fab,
  Icon,
  Input,
  List,
  ListHeader,
  ListItem,
  Navigator,
  Page,
  Tab,
  Tabbar,
} from 'react-onsenui'

import { AllBaseLogs } from './AllBaseLogs/AllBaseLogs'
import { AppToolbar } from 'components/AppToolbar/AppToolbar'
import { EventDescription } from 'types/EventDescription'
import { Log } from 'types/Log'
import { LogTable } from './LogTable/LogTable'
import { LoggingActions } from './LoggingActions/LoggingActions'
import React from 'react'
import { currentEventSelector } from 'ReduxState/reducers/currentEvent'
import { useAppSelector } from 'ReduxState/hooks'

export type BasePageProps = {
  navigator: Navigator
}

export const BasePage = ({ navigator }: BasePageProps) => {
  const { eventDescription, activeBase } = useAppSelector(currentEventSelector)
  if (!eventDescription) {
    return <Page />
  }
  const base = eventDescription.bases.find(
    ({ baseNo }) => baseNo === activeBase
  )
  if (!base) {
    return <Page>Something has gone wrong</Page>
  }
  const { baseName, baseInstructions, baseGeolocation, baseMaxScore, baseNo } =
    base
  const { pRef = 'Team' } = eventDescription
  return (
    <Page
      id="page1"
      renderToolbar={() => (
        <AppToolbar
          navigator={navigator}
          title={`Base ${baseNo}: ${baseName}`}
          hideSearch
        />
      )}
    >
      <Tabbar
        position="auto"
        index={0}
        renderTabs={() => [
          {
            content: <LoggingActions />,
            tab: <Tab label="Actions" icon="fa-clipboard-list" />,
          },
          {
            content: <AllBaseLogs />,
            tab: <Tab label="Base Logs" icon="fa-list" />,
          },
        ]}
      />
    </Page>
  )
}

const Old = ({ base, pRef }: EventDescription & { base: string }) => (
  <>
    FAB
    <Fab className="fab" position="bottom right">
      <Icon icon="md-edit" className="fabIcon" id="fullEditIcon" />
    </Fab>
    {/*Top half of screen*/}
    <div id="p1TopHalf">
      <List>
        <ListHeader id="quickAddTitle">
          Add new log from checkpoint {base}
        </ListHeader>
        <ListItem>
          <div className="center participantRef">{pRef} No.</div>
          <div className="right">
            <Input
              id="patrolNo"
              type="number"
              modifier="underbar"
              placeholder="Team No."
              float
              className="quickAddInput"
              min={0}
            />
          </div>
        </ListItem>
        <ListItem tappable className="checkbox" id="offRouteCheckbox">
          <label className="right">
            <Checkbox input-id="offRoute" value="offRoute"></Checkbox>
          </label>
          <label htmlFor="offRoute" className="center">
            Off route
          </label>
        </ListItem>
        <ListItem className="page1Time">
          <div className="center">Time in</div>
          <div className="right">
            <Input
              id="timeIn"
              type="time"
              modifier="underbar"
              float
              className="quickAddInput"
            ></Input>
          </div>
        </ListItem>
        <ListItem className="page1Time">
          <div className="center">Wait time</div>
          <div className="right">
            <Input
              id="wait"
              type="number"
              modifier="underbar"
              placeholder="minutes"
              value="0"
              float
              className="quickAddInput"
            ></Input>
          </div>
        </ListItem>
        <ListItem className="page1Time">
          <div className="center">Time out</div>
          <div className="right">
            <Input
              id="timeOut"
              type="time"
              modifier="underbar"
              float
              className="quickAddInput"
            ></Input>
          </div>
        </ListItem>
        <ListItem id="page1TotalScore" className="">
          <div className="center">Total score</div>
          <div className="right">
            <Input
              id="total"
              type="number"
              modifier="underbar"
              placeholder="Total score"
              float
              className="quickAddInput"
            ></Input>
          </div>
        </ListItem>
      </List>
      <p className="txtRight marginTop">
        <Button modifier="" className="" id="cancelSubmitQuick">
          Clear
        </Button>
        <Button className="secondaryColorButton" id="submitQuick">
          Submit
        </Button>
      </p>
    </div>
    {/*Bottom Half*/}
    <div id="p1BottomHalf">
      <List>
        <ListHeader id="tableTitle">
          Previous logs from this checkpoint
        </ListHeader>
      </List>
      <LogTable pRef={pRef} />
    </div>
  </>
)
