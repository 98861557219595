import { Log, LogAction } from 'types/Log'
import { noCheckIn, previousCheckOut } from './loggingErrorMessages'

import { baseLogIdGenerator } from 'utils/logIdGenerators'
import { clientSideApi } from 'api/clientSideApi'
import { currentEventSelector } from 'ReduxState/reducers/currentEvent'
import { format } from 'date-fns'
import { getGeolocation } from 'utils/getGeolocation'
import ons from 'onsenui'
import { useAppSelector } from 'ReduxState/hooks'
import { useCallback } from 'react'
import { usePouch } from 'use-pouchdb'
import { userSelector } from 'ReduxState/reducers/user'

export const useCheckOut = (onComplete?: () => void) => {
  const db = usePouch()
  const { eventDescription, activeBase, base } =
    useAppSelector(currentEventSelector)
  const { reportingUsername } = useAppSelector(userSelector)
  const {
    pRef = 'Team',
    geolocationInUse,
    geolocationPaid,
    trackingUrl,
  } = eventDescription || {}

  const { baseGeolocation = false } = base || {}
  const useGeolocation = geolocationInUse && geolocationPaid && baseGeolocation
  return useCallback(
    async ({
      patrol,
      totalScore,
      timeWait,
    }: Pick<Log, 'patrol' | 'totalScore' | 'timeWait'>) => {
      try {
        const id = baseLogIdGenerator({ patrol, base: activeBase })
        const d = new Date()
        const timestamp = d.toISOString()

        let doc: Log
        try {
          doc = await db.get<Log>(id)
        } catch (e) {
          const confirmation: 1 | 0 = (await ons.notification.confirm({
            title: `${pRef} ${patrol} has not been checked in`,
            messageHTML: noCheckIn(pRef),
          })) as any
          if (confirmation === 0) {
            return onComplete?.()
          }
          doc = {
            _id: id,
            patrol,
            base: activeBase,
            timeIn: timestamp,
            timeOut: timestamp,
            timeWait: 0,
            offRoute: '',
            username: reportingUsername,
            totalScore: 0,
            timestamp: timestamp,
            editable: true,
            action: LogAction.CHECK_IN,
          }
        }
        // If team already checked out
        if (doc.action === LogAction.CHECK_OUT) {
          const confirmation: 1 | 0 = (await ons.notification.confirm({
            title: `${pRef} ${doc.patrol} previously checked out`,
            messageHTML: previousCheckOut(doc, pRef),
          })) as any
          if (confirmation === 0) {
            return onComplete?.()
          }
        }

        if (useGeolocation) {
          try {
            const location = await getGeolocation()
            doc.geolocation = {
              lat: location.coords.latitude,
              lon: location.coords.longitude,
              accuracy: location.coords.accuracy,
            }
          } catch (e) {
            console.log('geolocation failed')
          }
        }
        const newDoc = {
          ...doc,
          username: reportingUsername,
          totalScore,
          timeWait,
          timestamp: timestamp,
          timeOut: timestamp,
          timeIn: doc.timeIn || timestamp,
          action: LogAction.CHECK_OUT,
        }
        try {
          await db.put(newDoc)
          if (useGeolocation && trackingUrl) {
            await clientSideApi.sendViaOsmAnd({ pRef, log: doc, trackingUrl })
          }
          onComplete?.()
          ons.notification.toast(
            `${pRef} ${patrol} logged as checked out of Base ${activeBase} @ ${format(
              d,
              'HH:mm:ss'
            )}`,
            { timeout: 3500, animation: 'fall' }
          )
        } catch (e) {
          const err = e as Error
          onComplete?.()
          ons.notification.alert(err.message)
        }
        onComplete?.()
      } catch (e) {}
    },
    [activeBase, db, onComplete, pRef, useGeolocation, reportingUsername]
  )
}
