import React, { PropsWithChildren } from 'react'

import { Icon } from 'react-onsenui'
import styles from './IconDataBox.module.css'

export type IconDataBoxProps = PropsWithChildren<{
  icon: string
  title: string
}>

export const IconDataBox = ({ icon, title, children }: IconDataBoxProps) => {
  return (
    <div className={styles.container}>
      <Icon icon={icon} size={30} />
      <b>{title}</b>
      <div className={styles.value}>{children}</div>
    </div>
  )
}
