import { List, ListItem, Page } from 'react-onsenui'
import {
  currentEventSelector,
  setActiveBase,
} from 'ReduxState/reducers/currentEvent'
import { useAppDispatch, useAppSelector } from 'ReduxState/hooks'

import React from 'react'
import { Routes } from 'constants/routes'
import { forcePageChange } from 'ReduxState/reducers/layout'
import { userSelector } from 'ReduxState/reducers/user'

// import {
//   adminFunctionsSelector,
//   toggleMap,
// } from 'ReduxState/reducers/adminFunctions'

// export type SideMenuProps = {}

export const SideMenu = () => {
  const { activeBase } = useAppSelector(currentEventSelector)
  const { evtOrganiser } = useAppSelector(userSelector)
  // const { showMap } = useAppSelector(adminFunctionsSelector)
  const dispatch = useAppDispatch()

  // const isAdmin = activeBase === 0

  return (
    <Page>
      <div>
        <img
          src="img/largeLogo.svg"
          alt="The logo for Checkpoint Live: Checkpoint Live, track together"
          className="menuTopBoxContent"
          height="100px"
        />
      </div>
      <List id="opFounderMenu">
        {activeBase > -1 && (
          <ListItem
            id="baseLogOut"
            onClick={() => {
              dispatch(
                forcePageChange({
                  method: 'push',
                  page: Routes.EVENT_SIGN_IN_PAGE,
                })
              )
              dispatch(setActiveBase(-1))
            }}
            tappable
          >
            Leave Base
          </ListItem>
        )}
        {/* {isAdmin && (
          <>
            <ListItem onClick={() => dispatch(toggleMap())} tappable>
              {showMap ? 'Hide' : 'Show'} Map
            </ListItem>
          </>
        )} */}
        {/* {isAdmin && <ListItem tappable>Copy All Logs Table</ListItem>} */}
        {evtOrganiser && (
          <>
            {/* <ListItem
              // onClick="goToEventSummary()"
              id="goToEventSummary"
              tappable
            >
              Event Summary
            </ListItem>

            <ListItem
              id="eventEditor"
              // onClick="editEvent()"
              tappable
            >
              Edit Event Set-up
            </ListItem> */}
            <ListItem
              onClick={() => {
                dispatch(
                  forcePageChange({
                    page: Routes.EVENT_SELECTION_PAGE,
                    method: 'push',
                  })
                )
              }}
              tappable
            >
              Switch Event
            </ListItem>
          </>
        )}
        <ListItem
          id="eventSignOut"
          onClick={() => {
            delete localStorage.user
            delete localStorage.currentDb
            delete localStorage.activeBase
            dispatch(
              forcePageChange({
                page: Routes.HOME,
                method: 'push',
              })
            )
          }}
          tappable
        >
          Sign Out
        </ListItem>
      </List>
    </Page>
  )
}
