import { EventDescription } from 'types/EventDescription'
import { FetchWrapper } from './FetchWrapper'
import { Log } from 'types/Log'
import { LoginResponse } from './clientSideApiTypes'
import { changeAtSymbol } from 'utils/changeAtSymbol'

class ClientSideApi extends FetchWrapper {
  constructor(baseUrl?: string) {
    super(baseUrl)
  }

  login = async ({
    username,
    password,
  }: {
    username: string
    password: string
  }): Promise<LoginResponse> => {
    // const formData = new FormData()
    // formData.append('username', changeAtSymbol(username))
    // formData.append('password', password)

    return this.post({
      url: 'signin',
      body: { username: changeAtSymbol(username), password },
    })
  }

  sendViaOsmAnd = async ({
    pRef,
    log,
    trackingUrl,
  }: {
    pRef: EventDescription['pRef']
    log: Log
    trackingUrl: string
  }): Promise<{
    status: 200
    message: 'Tracking information sent.'
    info: any
  }> => {
    if (!log.geolocation) {
      throw new Error('No Location Information')
    }
    const body = {
      trackingUrl: trackingUrl,
      id: pRef + '-' + log.patrol,
      lat: log.geolocation.lat,
      lon: log.geolocation.lon,
      accuracy: log.geolocation.accuracy,
      loggedBy: log.username,
      checkpoint: log.base,
      score: log.totalScore,
      offRoute: log.offRoute,
    }
    return this.post({ url: 'event/tracking', body })
  }
}

export const clientSideApi = new ClientSideApi(
  `${process.env.REACT_APP_PUBLIC_API_BASE_URL}/api/v2`
)
