import { isSameDay, sub } from 'date-fns'

export const messageDateString = (date: Date) => {
  const today = new Date()
  const yesterday = sub(new Date(), { days: 1 })

  if (isSameDay(today, date)) {
    return 'Today'
  } else if (isSameDay(yesterday, date)) {
    return 'Yesterday'
  } else {
    return date.toDateString()
  }
}
