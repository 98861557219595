import { setGeolocationStatus, userSelector } from 'ReduxState/reducers/user'
import { useAppDispatch, useAppSelector } from 'ReduxState/hooks'
import { useEffect, useState } from 'react'

import { AlertOptions } from 'types/AlertOptions'
import { currentEventSelector } from 'ReduxState/reducers/currentEvent'
import { getGeolocation } from 'utils/getGeolocation'
import ons from 'onsenui'

let geolocationRunning = false

export const useGeolocationNotification = () => {
  const { eventDescription } = useAppSelector(currentEventSelector)
  const { geolocation } = useAppSelector(userSelector)
  const dispatch = useAppDispatch()
  const [notificationMade, setNotificationMade] = useState(false)
  useEffect(() => {
    if (!eventDescription || notificationMade) {
      return
    }
    const { geolocationInUse } = eventDescription
    if (
      !geolocationRunning &&
      geolocationInUse &&
      typeof geolocation === 'undefined'
    ) {
      setNotificationMade(true)
      geolocationRunning = true
      var settings: AlertOptions = {
        title: 'Location',
        messageHTML:
          '<p class="">The event organiser has set this checkpoint to include a location with each log.</p><p>After closing this message a location check will be performed, select whether you would be happy to allow checkpointlive.com to use your location.</p>',
        cancelable: true,
      }

      ons.notification
        .alert(settings)
        .then(function () {
          return getGeolocation()
        })
        .then(function (location) {
          const options: AlertOptions = {
            title: 'Your current location',
            messageHTML:
              '<p class="">Thank you for accepting geolocation privileges, your current location is shown below:</p><div><p class="txtCenter">lat: ' +
              location.coords.latitude +
              '</p><p class="txtCenter">lon: ' +
              location.coords.longitude +
              '</p><p class="txtCenter">Accuracy: ' +
              location.coords.accuracy +
              'm</p></div>',
            cancelable: true,
          }
          if (location.coords.accuracy > 100) {
            options.messageHTML +=
              '<p class="caption ">Accuracy will improve as the GPS finds satellites.</p>'
          }
          dispatch(setGeolocationStatus('accepted'))
          return ons.notification.alert(options)
        })
        .catch(function (err) {
          console.log(err)
          const options: AlertOptions = {
            title: 'Location Failed',
            message:
              'If you did not accept to allow for locations, if you change your mind you can do so from your browser settings. If not then it seems your browser cannot utilise geolocation.',
            cancelable: true,
          }
          dispatch(setGeolocationStatus('rejected'))
          return ons.notification.alert(options)
        })
      geolocationRunning = false
    }
  }, [dispatch, eventDescription, geolocation])
}
