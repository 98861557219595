import './App.css'

import {
  Navigator,
  Page,
  Splitter,
  SplitterContent,
  SplitterSide,
} from 'react-onsenui'
import React, { useRef } from 'react'
import { layoutSelector, toggleSidebar } from './ReduxState/reducers/layout'
import { useAppDispatch, useAppSelector } from './ReduxState/hooks'

import { AppRoutes } from 'AppRoutes/AppRoutes'
import { Routes } from 'constants/routes'
import { SideMenu } from './AppRoutes/SideMenu/SideMenu'
import { currentEventSelector } from 'ReduxState/reducers/currentEvent'
import { userSelector } from 'ReduxState/reducers/user'

const setInitialTitle = ({
  baseRemoteUrl,
  currentDb,
  activeBase,
  evtOrganiser,
}: {
  baseRemoteUrl?: string
  currentDb?: string
  evtOrganiser?: boolean
  activeBase: number
}) => {
  if (baseRemoteUrl && currentDb) {
    if (activeBase === 0) {
      return Routes.ADMIN
    }
    if (activeBase > 0) {
      return Routes.BASE_PAGE
    }
    return Routes.EVENT_SIGN_IN_PAGE
  }

  if (evtOrganiser && baseRemoteUrl) {
    return Routes.EVENT_SELECTION_PAGE
  }

  return Routes.HOME
}

function App() {
  const { sidebarOpen } = useAppSelector(layoutSelector)
  const { baseRemoteUrl, evtOrganiser } = useAppSelector(userSelector)
  const { currentDb, activeBase } = useAppSelector(currentEventSelector)
  const dispatch = useAppDispatch()
  const navigatorRef = useRef<Navigator | null>(null)
  const initialTitle = setInitialTitle({
    baseRemoteUrl,
    currentDb,
    evtOrganiser,
    activeBase,
  })
  // sets the initial Route

  return (
    <Splitter>
      <SplitterSide
        side="left"
        collapse
        swipeable
        isOpen={sidebarOpen}
        onClose={() => dispatch(toggleSidebar(false))}
        swipe-target-width="1px"
      >
        <SideMenu />
      </SplitterSide>
      <SplitterContent>
        <Navigator
          id="navi"
          ref={navigatorRef}
          animation="none"
          renderPage={(route, navigator) => {
            if (!navigator) {
              return <Page key="noNav" />
            }
            return (
              <AppRoutes
                route={route}
                navigator={navigator}
                key={route.title}
              />
            )
          }}
          initialRoute={{
            title: initialTitle,
            hasBackButton: false,
          }}
        />
      </SplitterContent>
    </Splitter>
  )
}

export default App
