import React, { useEffect } from 'react'
import {
  currentEventSelector,
  loadCurrentBase,
  loadEventDescription,
} from 'ReduxState/reducers/currentEvent'
import { useAppDispatch, useAppSelector } from 'ReduxState/hooks'

import { Admin } from 'AppRoutes/Admin/Admin'
import { BasePage } from 'AppRoutes/LoggingPage/BasePage'
import { EventDescription } from 'types/EventDescription'
import { EventSignInPage } from 'AppRoutes/EventSignInPage/EventSignInPage'
import { MessagingPage } from 'AppRoutes/MessagingPage/MessagingPage'
import { Routes } from 'constants/routes'
import { SyncRoutesProps } from 'AppRoutes/SyncRoutes'
import { useDoc } from 'use-pouchdb'

export const SyncRouteInner = ({ route, navigator }: SyncRoutesProps) => {
  const { doc: eventDescription } = useDoc<EventDescription>('eventDescription')
  const { activeBase } = useAppSelector(currentEventSelector)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (eventDescription) {
      dispatch(loadEventDescription(eventDescription))
    }
  }, [eventDescription, dispatch])

  useEffect(() => {
    if (eventDescription && activeBase > -1) {
      const base = eventDescription.bases.find((b) => b.baseNo === activeBase)
      if (base) {
        dispatch(loadCurrentBase(base))
      }
    }
  }, [activeBase, eventDescription, dispatch])

  switch (route.title) {
    case Routes.MESSAGING_PAGE:
      return <MessagingPage navigator={navigator} key={Routes.MESSAGING_PAGE} />
    case Routes.ADMIN:
      return <Admin navigator={navigator} key={Routes.ADMIN} />

    case Routes.BASE_PAGE:
      return <BasePage navigator={navigator} key={Routes.BASE_PAGE} />
    case Routes.EVENT_SIGN_IN_PAGE:
    default:
      return (
        <EventSignInPage
          navigator={navigator}
          key={Routes.EVENT_SIGN_IN_PAGE}
        />
      )
  }
}
