import {
  BackButton,
  Icon,
  Navigator,
  Toolbar,
  ToolbarButton,
} from 'react-onsenui'
import { Form, Formik } from 'formik'
import React, { ReactNode, useState } from 'react'
import {
  currentEventSelector,
  setTeamSearch,
} from 'ReduxState/reducers/currentEvent'
import { useAppDispatch, useAppSelector } from 'ReduxState/hooks'

import { FormikInput } from 'components/FormikInput/FormikInput'
import { Messaging } from './Messaging/Messaging'
import { toggleSidebar } from 'ReduxState/reducers/layout'

export type AppToolbarProps = {
  navigator: Navigator
  title?: string | ReactNode
  hideSearch?: boolean
  hideMessaging?: boolean
  backButton?: boolean
}

export const AppToolbar = ({
  navigator,
  title,
  hideMessaging,
  hideSearch,
  backButton,
}: AppToolbarProps) => {
  const { lastSync, currentDb } = useAppSelector(currentEventSelector)
  const [showSearch, setShowSearch] = useState(false)
  const dispatch = useAppDispatch()

  return (
    <Toolbar modifier="material">
      {!backButton ? (
        <div className="left">
          <ToolbarButton
            className="menuButton"
            onClick={() => dispatch(toggleSidebar())}
          >
            <Icon icon="md-menu" />
          </ToolbarButton>
        </div>
      ) : (
        <BackButton>Back</BackButton>
      )}

      {(!showSearch || hideSearch) && (
        <div className="center pageTitle">
          {currentDb ? (
            <div className="syncTitle">
              <p className="mainTitle">{title}</p>
              <p className="lastSync">last sync: {lastSync}</p>
            </div>
          ) : (
            title
          )}
        </div>
      )}

      <div className="right" style={{ display: 'flex', alignItems: 'center' }}>
        {!hideSearch && showSearch && (
          <Formik
            initialValues={{ patrol: '' }}
            onSubmit={({ patrol }) => {
              dispatch(setTeamSearch(patrol ? `${patrol}` : undefined))
              if (!patrol) {
                setShowSearch(false)
              }
            }}
          >
            <Form>
              <FormikInput type="number" name="patrol" noError autoFocus />
            </Form>
          </Formik>
        )}
        {!hideSearch && (
          <ToolbarButton
            onClick={() => {
              setShowSearch(true)
            }}
          >
            <Icon icon="md-search" />
          </ToolbarButton>
        )}

        {!hideMessaging && <Messaging navigator={navigator} />}
      </div>
    </Toolbar>
  )
}
