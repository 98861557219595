import React, { useCallback } from 'react'
import {
  adminFunctionsSelector,
  selectLog,
  shiftSelectLog,
} from 'ReduxState/reducers/adminFunctions'
import { useAppDispatch, useAppSelector } from 'ReduxState/hooks'

import { Log } from 'types/Log'
import { TableLogRow } from 'components/TableLogRow/TableLogRow'

export type AdminTableLogProps = Log & {}

export const AdminTableLog = (props: AdminTableLogProps) => {
  const { _id } = props
  const dispatch = useAppDispatch()
  const { selectedLogs } = useAppSelector(adminFunctionsSelector)
  const selected = !!selectedLogs[_id]

  const onSelectRow = useCallback(
    (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
      if (e.shiftKey) {
        return dispatch(shiftSelectLog(_id))
      }
      return dispatch(selectLog(_id))
    },
    [_id, dispatch]
  )
  return <TableLogRow {...props} onClick={onSelectRow} selected={selected} />
}
