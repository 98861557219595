import { FieldAttributes, useField } from 'formik'
import React, { useEffect, useRef } from 'react'

import { InputErrorMessage } from 'components/InputErrorMessage/InputErrorMessage'
import clsx from 'clsx'
import styles from './formikInput.module.css'

export type FormikInputProps = FieldAttributes<any> & { noError?: boolean }

export const FormikInput = ({
  label,
  className,
  autoFocus = false,
  noError,
  ...props
}: FormikInputProps) => {
  const inputRef = useRef<HTMLInputElement | null>()
  const [field, meta] = useField(props)
  const hasError = Boolean(!noError && meta.touched && meta.error)

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [autoFocus])

  return (
    <div className={styles.container}>
      {label && (
        <label className={styles.label} htmlFor={props.name}>
          {label}
        </label>
      )}
      <input
        ref={inputRef}
        {...field}
        {...props}
        id={props.name}
        float
        className={clsx(className, styles.input, { [styles.error]: hasError })}
      />
      <InputErrorMessage hasError={hasError} error={meta.error} />
    </div>
  )
}
