import React, { useEffect, useState } from 'react'
import {
  differenceInMilliseconds,
  format,
  isBefore,
  isToday,
  startOfDay,
} from 'date-fns'

import { Log } from 'types/Log'
import clsx from 'clsx'
import styles from './LastSeenRow.module.css'

export type LastSeenRowProps = Log

export const LastSeenRow = ({
  patrol,
  timeIn,
  timeOut,
  username: recordedBy,
  base,
  offRoute,
}: LastSeenRowProps) => {
  const timeLastSeen = timeOut || timeIn
  const [isWarning, setIsWarning] = useState(false)

  useEffect(() => {
    const diffInMs = differenceInMilliseconds(
      new Date(),
      new Date(timeLastSeen)
    )
    const diffToHour = 60 * 1000 * 60 - diffInMs
    const diffTo90Mins = 60 * 1000 * 90 - diffInMs

    if (diffToHour > 0) {
      const timer = setTimeout(
        () => {
          setIsWarning(true)
        },
        diffTo90Mins > 0 ? diffTo90Mins : diffToHour
      )
      return () => {
        clearTimeout(timer)
      }
    } else if (!isWarning) {
      setIsWarning(true)
    }
  }, [timeLastSeen, isWarning])

  return (
    <tr className={clsx({ [styles.warning]: isWarning })}>
      <td>
        <strong>{patrol}</strong>
      </td>
      <td>
        <strong>
          {format(
            new Date(timeLastSeen),
            isToday(new Date(timeLastSeen)) ? 'HH:mm' : 'dd/MM/yy HH:mm'
          )}
        </strong>
      </td>
      <td>{base}</td>
      <td>{offRoute ? 'off route' : 'on Route'}</td>
      <td>{recordedBy}</td>
    </tr>
  )
}
