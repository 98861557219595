import { Log } from 'types/Log'
import { Page } from 'react-onsenui'
import React from 'react'
import { TableLogRow } from 'components/TableLogRow/TableLogRow'
import { currentEventSelector } from 'ReduxState/reducers/currentEvent'
import { useAppSelector } from 'ReduxState/hooks'
import { useFind } from 'use-pouchdb'

export type AllBaseLogsProps = {}

export const AllBaseLogs = ({}: AllBaseLogsProps) => {
  const { activeBase, base } = useAppSelector(currentEventSelector)
  const { eventDescription } = useAppSelector(currentEventSelector)

  const { isTransport } = base || {}

  const { docs } = useFind<Log>({
    index: { fields: ['timeOut', 'base'] },
    selector: {
      timeOut: {
        $gt: 0,
      },
      base: activeBase,
    },
    sort: [{ timeOut: 'desc' }],
  })

  const { pRef = 'Team' } = eventDescription || {}
  return (
    <Page>
      <table className="data-table logTable">
        <thead>
          <tr>
            {/* <th>Info</th> */}
            <th className="participantRef">{pRef}</th>
            {isTransport && <th>Time in</th>}
            <th>Time out</th>
            {/* <th>Wait</th> */}
            {/* <th>Off route</th> */}
            {!isTransport && <th>Score</th>}
            <th>Recorded by</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody id="adminLogsTable">
          {docs.map((log) => (
            <TableLogRow
              key={log._id}
              {...log}
              baseView
              isTransport={isTransport}
            />
          ))}
        </tbody>
      </table>
    </Page>
  )
}
