import React from 'react'
import { messageDateString } from 'utils/messageDateString'

export type DateBubbleProps = {
  date: Date
}

export const DateBubble = ({ date }: DateBubbleProps) => {
  return (
    <div className="msg dateMsgDivider" style={{ marginBottom: '1rem' }}>
      <div className="bubble dateBubble">{messageDateString(date)}</div>
    </div>
  )
}
