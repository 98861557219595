export const remoteUrl = ({
  username,
  password,
  couchdb,
  http,
}: {
  username: string
  password: string
  http: string
  couchdb: string
}) => `${http}${username}:${password}@${couchdb}`
