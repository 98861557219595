import { Log, LogAction } from 'types/Log'

import { clientSideApi } from 'api/clientSideApi'
import { currentEventSelector } from 'ReduxState/reducers/currentEvent'
import { format } from 'date-fns'
import { getGeolocation } from 'utils/getGeolocation'
import ons from 'onsenui'
import { seenLogIdGenerator } from 'utils/logIdGenerators'
import { useAppSelector } from 'ReduxState/hooks'
import { useCallback } from 'react'
import { usePouch } from 'use-pouchdb'
import { userSelector } from 'ReduxState/reducers/user'

export const useSeen = (onComplete?: () => void) => {
  const db = usePouch()
  const { activeBase, eventDescription, base } =
    useAppSelector(currentEventSelector)
  const { reportingUsername } = useAppSelector(userSelector)
  const {
    pRef = 'Team',
    trackingUrl,
    geolocationInUse,
    geolocationPaid,
  } = eventDescription || {}
  const { baseGeolocation = false } = base || {}

  const useGeolocation = geolocationInUse && geolocationPaid && baseGeolocation

  return useCallback(
    async ({ patrol }: Pick<Log, 'patrol'>) => {
      try {
        const _id = seenLogIdGenerator({ patrol, base: activeBase })
        const d = new Date()
        const timestamp = d.toISOString()
        const doc: Log = {
          _id,
          patrol,
          base: activeBase,
          timeIn: timestamp,
          timeOut: timestamp,
          timeWait: 0,
          offRoute: true,
          username: reportingUsername,
          totalScore: undefined,
          timestamp: timestamp,
          editable: true,
          action: LogAction.SEEN,
        }
        if (useGeolocation) {
          try {
            const location = await getGeolocation()
            doc.geolocation = {
              lat: location.coords.latitude,
              lon: location.coords.longitude,
              accuracy: location.coords.accuracy,
            }
          } catch (e) {
            console.log('geolocation failed')
          }
        }

        await db.put(doc)
        if (useGeolocation && trackingUrl) {
          await clientSideApi.sendViaOsmAnd({ pRef, log: doc, trackingUrl })
        }

        onComplete?.()
        ons.notification.toast(
          `${pRef} ${patrol} logged as seen @ ${format(d, 'HH:mm:ss')}`,
          { timeout: 3000, animation: 'fall' }
        )
      } catch (e) {
        const err = e as Error
        onComplete?.()
        ons.notification.alert(err.message)
      }
    },
    [activeBase, db, pRef, onComplete, reportingUsername]
  )
}
