import { Fab, Icon } from 'react-onsenui'
import React, { useState } from 'react'

import clsx from 'clsx'
import styles from '../AllLogs.module.css'

export type AdminMapProps = {}

export const AdminMap = ({}: AdminMapProps) => {
  const [locOn, setLocOn] = useState(true)
  return (
    <div
      id="tab0Map"
      className={clsx('col-md-4 col-lg-6', styles.adminMapContainer)}
    >
      <Fab
        className={clsx(styles.fabLocate, {
          [styles.locateSelected]: locOn,
          [styles.locateNotSelected]: !locOn,
        })}
        id="fabLocateTab0"
        onClick={() => setLocOn(!locOn)}
      >
        <Icon
          icon="md-gps-dot"
          className="locateAlign locateSelected"
          id="fabLocateIcon"
        ></Icon>
      </Fab>
    </div>
  )
}
