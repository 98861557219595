import React, { useMemo } from 'react'

import { LeaderboardRow } from './LeaderboardRow/LeaderboardRow'
import { Page } from 'react-onsenui'
import { currentEventSelector } from 'ReduxState/reducers/currentEvent'
import { useAppSelector } from 'ReduxState/hooks'
import { useCheckForLeaderBoardIndex } from 'hooks/useCheckForLeaderBoardIndex'
import { useView } from 'use-pouchdb'

export type LeaderboardProps = {}

export const Leaderboard = ({}: LeaderboardProps) => {
  const { teamSearch } = useAppSelector(currentEventSelector)
  useCheckForLeaderBoardIndex()

  // todo: add loading state
  const { rows = [] } = useView('leaderboardIndex/leaderboardIndex', {
    reduce: true,
    group: true,
  })

  const leaderboardRows = useMemo(() => {
    const sortedRows = rows.sort(({ value: a }, { value: b }) => {
      return b - a
    })
    let lastRowValue = -1
    let position = 0
    const positionedSortedRows = sortedRows.map(({ value, key }) => {
      if (lastRowValue === value) {
        position--
      }
      position++
      lastRowValue = value
      return { value, position, teamNo: key }
    })
    if (!teamSearch) {
      return positionedSortedRows
    }
    return positionedSortedRows.filter(({ teamNo }) => teamNo === teamSearch)
  }, [rows, teamSearch])

  return (
    <Page id="leaderboard">
      <table className="data-table logTable">
        <thead>
          <tr>
            <th className="bold txtCenter">Position</th>
            <th className="txtCenter participantRef">Team</th>
            <th className="txtCenter">Total Score</th>
          </tr>
        </thead>
        <tbody id="leaderboardTable">
          {leaderboardRows.map(({ value, teamNo, position }) => (
            <LeaderboardRow
              key={teamNo}
              value={value}
              teamNo={teamNo}
              position={position}
            />
          ))}
        </tbody>
      </table>
    </Page>
  )
}
